import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable newline-per-chained-call */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import WebApp from '@twa-dev/sdk';
import { v1 } from 'uuid';
import { userState } from '../../../../features/user/model';
import { updatePrevLocation } from '../../../../features/prev-location/model';
import { caseDetails, setShowBoxDetails, showBoxDetails } from '../../../../features/box/model';
import { Modal } from '../../../../shared/ui/components';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import { formatNumber } from '../../../../shared/utils/formatNumber';
import BG from '../../../../shared/lib/images/background.png';
import LevelBig from '../../../../shared/lib/images/level-153.png';
import COIN from '../../../../shared/lib/images/coin-logo.png';
import ARBUZ from '../../../../shared/lib/images/arbuz.svg';
import DFC from '../../../../shared/lib/images/dfc.svg';
import Level from '../../../../shared/lib/images/level.svg';
import s from './case-detail.module.scss';
const ARBUZ_PREVIEW = 'https://cache.tonapi.io/imgproxy/DPjV-M30WgZW_b9-jr3XKe47Fo_9YA8A7NyP55Irj-8/rs:fill:200:200:1/g:no/aHR0cHM6Ly9pLnBvc3RpbWcuY2MvWEpnVmY3cGIvYXJiLnBuZw.webp';
const DFC_PREVIEW = 'https://cache.tonapi.io/imgproxy/G8iWJLhU0kgZtKbA8j11QWRJYyCbq6E-Loq4TkMMMpA/rs:fill:200:200:1/g:no/aHR0cHM6Ly90YW4tdG91Z2gtc2x1Zy0zNTEubXlwaW5hdGEuY2xvdWQvaXBmcy9RbVhRb2pKVVB2a0dDQ2VSOVF1OFd3bWNaRjFnTERZMjhlcExMaFBZdkR5OFRr.webp';
export const CaseDetail = ({ caseId, caseProcessing, setCaseId, resultData, handleOpenCase, setResultData, position, setPosition }) => {
    const { user } = useUnit(userState);
    const showBoxDetailState = useUnit(showBoxDetails);
    const caseDetail = useUnit(caseDetails);
    const TgObj = WebApp;
    const { t } = useTranslation();
    const [boxPreviewData, setBoxPreviewData] = useState(undefined);
    const [isSpinning, setIsSpinning] = useState(false);
    const [boxAwards, setBoxAwards] = useState([]);
    const navigate = useNavigate();
    const handleOpen = (caseId) => {
        handleOpenCase(caseId);
        TgObj.MainButton.showProgress();
        TgObj.MainButton.disable();
        TgObj.MainButton.color = '#999';
    };
    useEffect(() => {
        switch (caseId) {
            case 1:
                setBoxPreviewData({
                    rangeWinDFC: '0.1 - 23.4',
                    rangeWinArbuz: '0.1 - 14.2'
                });
                break;
            case 2:
                setBoxPreviewData({
                    rangeWinDFC: '0.1 - 93.6',
                    rangeWinArbuz: '0.1 - 56.8'
                });
                break;
            case 3:
                setBoxPreviewData({
                    rangeWinDFC: '0.1 - 70.2',
                    rangeWinArbuz: '0.1 - 42.6'
                });
                break;
            case 4:
                setBoxPreviewData({
                    rangeWinDFC: '0.1 - 140.4',
                    rangeWinArbuz: '0.1 - 85.2'
                });
                break;
            case 5:
                setBoxPreviewData({
                    rangeWinDFC: '0.1 - 234',
                    rangeWinArbuz: '0.1 - 142'
                });
                break;
            case 6:
                setBoxPreviewData({
                    rangeWinDFC: '0.01 - 100',
                    rangeWinArbuz: '0.01 - 100'
                });
                break;
            case 7:
                setBoxPreviewData({
                    rangeWinDFC: '0.1 - 2340',
                    rangeWinArbuz: '0.1 - 1420'
                });
                break;
            default:
        }
    }, [caseId]);
    const getMainButtonText = (position) => {
        if (typeof resultData?.win === 'object' && 'coin' in resultData?.win) {
            return;
        }
        switch (position) {
            case 0:
                return t('buttons.open');
            case 1:
                return t('buttons.roll');
            case 2:
                return t('buttons.try-again');
            default:
                return '';
        }
    };
    const handleMainButton = () => {
        if (typeof resultData?.win === 'object' && 'coin' in resultData?.win) {
            TgObj.openTelegramLink(resultData?.win?.link);
            setResultData(undefined);
            setPosition(0);
            handleCloseModal();
            window.location.reload();
            return;
        }
        if (caseDetail?.type === 'coin' && (user?.balance ?? 0) < (caseDetail?.price ?? 0)) {
            TgObj.MainButton.hide();
            handleCloseModal();
            navigate('/buy');
            updatePrevLocation('/profile');
            return;
        }
        if (caseDetail?.type === 'usd' && (user?.usdBalance ?? 0) < (caseDetail?.price ?? 0)) {
            TgObj.MainButton.hide();
            handleCloseModal();
            navigate('/buy');
            updatePrevLocation('/profile');
            return;
        }
        if (position === 0) {
            setPosition(1);
        }
        else if (position === 1) {
            handleOpen(caseId ?? 0);
            // TgObj.MainButton.hide()
        }
        else if (position === 2) {
            setResultData(undefined);
            setIsSpinning(false);
            setPosition(1);
        }
    };
    function handleCloseModal() {
        if (typeof resultData?.win === 'object' && 'coin' in resultData?.win) {
            TgObj.openTelegramLink(resultData?.win?.link);
            window.location.reload();
        }
        setCaseId({
            caseId: 0,
            type: 'usd'
        });
        setShowBoxDetails(false);
        setPosition(0);
        setResultData(undefined);
        window.document.body.style.overflow = 'visible';
        TgObj.MainButton.hide();
        TgObj.MainButton.offClick(handleMainButton);
    }
    useEffect(() => {
        TgObj.MainButton.color = '#007AFF';
        if (!showBoxDetailState)
            return;
        if (typeof resultData?.win === 'object' && 'coin' in resultData?.win) {
            if (isSpinning) {
                TgObj.MainButton.hide();
                return;
            }
            TgObj.MainButton.show();
            TgObj.MainButton.enable();
            TgObj.MainButton.text = t('buttons.activate-check');
            TgObj.MainButton.onClick(handleMainButton);
            return () => TgObj.MainButton.offClick(handleMainButton);
        }
        if (caseDetail?.type === 'coin' && (user?.balance ?? 0) < (caseDetail?.price ?? 0)) {
            TgObj.MainButton.show();
            TgObj.MainButton.enable();
            TgObj.MainButton.color = '#007AFF';
            TgObj.MainButton.text = t('buttons.top-up');
            TgObj.MainButton.onClick(handleMainButton);
            return () => TgObj.MainButton.offClick(handleMainButton);
        }
        if (caseDetail?.type === 'usd' && (user?.usdBalance ?? 0) < (caseDetail?.price ?? 0)) {
            TgObj.MainButton.show();
            TgObj.MainButton.enable();
            TgObj.MainButton.color = '#007AFF';
            TgObj.MainButton.text = t('buttons.top-up');
            TgObj.MainButton.onClick(handleMainButton);
            return () => TgObj.MainButton.offClick(handleMainButton);
        }
        TgObj.MainButton.enable();
        TgObj.MainButton.text = getMainButtonText(position) || '';
        if (position !== 1) {
            TgObj.MainButton.show();
        }
        TgObj.MainButton.onClick(handleMainButton);
        return () => TgObj.MainButton.offClick(handleMainButton);
    }, [position, caseId, resultData, isSpinning, user, boxPreviewData]);
    useEffect(() => {
        if (resultData) {
            setIsSpinning(true);
            TgObj.MainButton.hide();
            // setTimeout(() => {
            //     setIsSpinning(false)
            // }, 1500)
        }
        if (!isSpinning && resultData) {
            setTimeout(() => {
                setPosition(2);
                setIsSpinning(false);
                TgObj.MainButton.show();
            }, 4200);
        }
    }, [resultData]);
    //= =======================================================================================================================================================
    if (!caseId)
        return _jsx(_Fragment, {});
    const boxDataMocks = [
        {
            type: 'coin',
            win: 5000
        },
        {
            type: 'coin',
            win: 10000
        },
        {
            type: 'coin',
            win: 15000
        },
        {
            type: 'coin',
            win: 20000
        },
        {
            type: 'coin',
            win: 50000
        },
        {
            type: 'coin',
            win: 70000
        },
        {
            type: 'DFC',
            win: 25
        },
        {
            type: 'ARBUZ',
            win: 25
        },
        {
            type: 'level',
            win: 1
        }
    ];
    const boxDataMocksMeta = [
        {
            type: 'DFC',
            win: 1
        },
        {
            type: 'ARBUZ',
            win: 2
        },
        {
            type: 'DFC',
            win: 3
        },
        {
            type: 'ARBUZ',
            win: 4
        },
        {
            type: 'DFC',
            win: 5
        },
        {
            type: 'ARBUZ',
            win: 6
        },
        {
            type: 'DFC',
            win: 7
        },
        {
            type: 'ARBUZ',
            win: 8
        },
        {
            type: 'DFC',
            win: 9
        }
    ];
    useEffect(() => {
        if (caseId === 7)
            return;
        const dfcElement = caseDetail?.awards.find(element => element.type === 'DFC');
        const arbuzElement = caseDetail?.awards.find(element => element.type === 'ARBUZ');
        const coinElements = caseDetail?.awards.filter(element => element.type === 'coin');
        if (!coinElements || coinElements.length < 7) {
            return setBoxAwards(boxDataMocks);
        }
        const shuffledCoinElements = [...coinElements].sort(() => Math.random() - 0.5);
        const finalArray = [dfcElement, arbuzElement, ...shuffledCoinElements.slice(0, 7)];
        const shuffledArray = [...finalArray].sort(() => Math.random() - 0.5);
        setBoxAwards(shuffledArray);
    }, [caseId]);
    const boxDataMocksFull = caseId === 7
        ? [...Array(9)].flatMap(() => boxDataMocksMeta)
        : [...Array(9)].flatMap(() => boxAwards);
    const mocksDataCases = caseId === 7 ? boxDataMocksMeta : boxAwards;
    const finalMock = [
        ...boxDataMocksFull,
        {
            type: typeof resultData?.win === 'object' && 'coin' in resultData?.win
                ? resultData?.win.coin
                : typeof resultData?.win === 'object' && 'level' in resultData?.win
                    ? 'level'
                    : 'coin',
            win: resultData?.win
        },
        ...mocksDataCases
    ];
    //= =======================================================================================================================================================
    const uniqueDFC = new Set();
    const uniqueARBUZ = new Set();
    const filteredArray = (caseId !== 7 ? caseDetail?.awards : boxDataMocksMeta)?.filter((item) => {
        if (item.type === 'DFC') {
            if (!uniqueDFC.has(item.type)) {
                uniqueDFC.add(item.type);
                return true;
            }
        }
        else if (item.type === 'ARBUZ') {
            if (!uniqueARBUZ.has(item.type)) {
                uniqueARBUZ.add(item.type);
                return true;
            }
        }
        else {
            return true;
        }
    });
    return (_jsx(_Fragment, { children: _jsxs(Modal, { isOpen: showBoxDetailState, onClose: handleCloseModal, isCloseButton: false, className: s.modal, children: [_jsx("img", { src: BG, className: s.modalBg, alt: "bg" }), _jsxs("div", { className: s.modalWrapper, children: [!isSpinning && (_jsx("div", { className: s.modalTop, children: _jsx("button", { className: s.modalTopButton, onClick: handleCloseModal, children: _jsx(SvgSelector, { id: "close" }) }) })), _jsxs("div", { className: s.modalInner, children: [position !== 2 && (_jsxs(_Fragment, { children: [_jsx("img", { src: caseDetail?.previewImg, alt: "box preview" }), _jsx("p", { children: t(caseDetail?.title ?? '') }), _jsx("div", { className: s.modalInnerPrice, children: _jsxs("span", { children: [caseDetail?.type === 'coin' ? (_jsx(SvgSelector, { id: "coin" })) : (_jsx(SvgSelector, { id: "usd-logo-small" })), caseDetail?.type === 'usd'
                                                        ? caseDetail?.price
                                                        : formatNumber(caseDetail?.price ?? 0)] }) })] })), position === 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: s.modalInnerAwards, children: caseDetail?.awards
                                                ?.filter(el => el?.type !== 'DFC' && el.type !== 'ARBUZ')?.sort((e, b) => e.win - b.win)
                                                ?.map(el => (_jsxs("div", { className: `
                                    ${s.modalInnerAward} 
                                    ${el.type === 'coin' && Number(el?.win) >= 200000
                                                    ? s.modalInnerAwardCoin
                                                    : ''}
                                    ${el.type === 'level' ? s.modalInnerAwardLevel : ''}
                                `, children: [el.type === 'coin' && (_jsxs(_Fragment, { children: [_jsx(SvgSelector, { id: "logo-coin-small" }), formatNumber(Number(el?.win))] })), el.type === 'level' && (_jsxs(_Fragment, { children: [_jsx(Level, {}), t('common.level')] }))] }, v1()))) }), _jsx("div", { className: s.modalInnerAwardsCoins, children: filteredArray
                                                ?.filter((el) => el.type === 'DFC' || el.type === 'ARBUZ')
                                                .map((el) => (_jsxs("div", { className: `                    
                                    ${s.modalInnerAward}                 
                                    ${el.type === 'ARBUZ' ? s.modalInnerAwardArbuz : ''}
                                    ${el.type === 'DFC' ? s.modalInnerAwardDFC : ''}`, children: [el.type === 'DFC' && (_jsxs(_Fragment, { children: [_jsx(DFC, {}), "DFC"] })), el.type === 'ARBUZ' && (_jsxs(_Fragment, { children: [_jsx(ARBUZ, {}), "ARBUZ"] })), _jsxs("span", { children: [el.type === 'DFC'
                                                                && boxPreviewData?.rangeWinDFC, el.type === 'ARBUZ'
                                                                && boxPreviewData?.rangeWinArbuz] })] }, v1()))) })] })), position === 1 && (_jsx("div", { className: s.modalAwardsGround, children: _jsx(motion.div, { className: `${s.modalGround}`, style: { justifyContent: 'center' }, children: _jsxs(AnimatePresence, { children: [_jsx("div", { className: ` ${s.roll} ${isSpinning ? s.spinningRoll : ''}`, children: finalMock.map(el => (_jsxs("div", { className: `${s.modalGroundItem}                                     
                                                ${el?.type === 'coin'
                                                            && (Number(el?.win) ?? 0) >= 200000
                                                            ? s.modalInnerAwardCoin
                                                            : ''}
                                                ${el?.type === 'DFC' ? s.modalInnerAwardDFC : ''}
                                                ${el?.type === 'ARBUZ'
                                                            ? s.modalInnerAwardArbuz
                                                            : ''}
                                                ${el?.type === 'level'
                                                            ? s.modalInnerAwardLevel
                                                            : ''}
                                               
                                            `, style: {
                                                            display: !el?.win ? 'none' : '',
                                                            animation: !isSpinning
                                                                ? 'easy-out'
                                                                : ''
                                                        }, children: [el?.type === 'coin' && (_jsxs(_Fragment, { children: [_jsx(SvgSelector, { id: "logo-coin-small" }), formatNumber(Number(el?.win))] })), el?.type === 'level' && (_jsxs(_Fragment, { children: [_jsx(Level, {}), t('common.level')] })), el?.type === 'DFC' && (_jsxs(_Fragment, { children: [_jsx(DFC, {}), "DFC"] })), el?.type === 'ARBUZ' && (_jsxs(_Fragment, { children: [_jsx(ARBUZ, {}), "ARBUZ"] }))] }, v1()))) }), _jsx("div", { className: s.modalGroundDivider })] }) }) })), position === 2 && (_jsxs("div", { className: s.modalResult, children: [typeof resultData?.win === 'object'
                                            && 'coin' in resultData?.win && (_jsxs(_Fragment, { children: [_jsx("img", { src: resultData?.win?.coin === 'DFC'
                                                        ? DFC_PREVIEW
                                                        : ARBUZ_PREVIEW, alt: "preview" }), _jsx("h2", { className: s.modalTitle, children: t('common.you-win') }), _jsxs("div", { className: s.modalAmount, children: [resultData?.win?.reward, ' ', resultData?.win?.coin === 'DFC' ? 'DFC' : 'ARBUZ'] })] })), typeof resultData?.win === 'number' && (_jsxs(_Fragment, { children: [_jsx("img", { src: COIN, alt: "coin" }), _jsx("h2", { className: s.modalTitle, children: t('common.you-win') }), _jsxs("div", { className: s.modalAmount, children: [_jsx(SvgSelector, { id: "logo-coin-small" }), formatNumber(resultData?.win)] })] })), typeof resultData?.win === 'object'
                                            && 'level' in resultData?.win && (_jsxs(_Fragment, { children: [_jsx("img", { src: LevelBig, alt: "level" }), _jsx("h2", { className: s.modalTitle, children: t('common.you-win') }), _jsx("div", { className: s.modalAmount, children: t('common.level') })] })), _jsxs("p", { children: [t('common.case'), ": ", t(caseDetail?.title ?? '')] })] }))] })] })] }) }));
};
