// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.er9MWdyAbN_AKbdNkT0v{border:4px solid rgba(255,255,255,.3);border-radius:50%;border-top:4px solid #158dd8;width:40px;height:40px;animation:R4iNuFBGscQgShxbup4A 1s linear infinite}.ipntgEPiPwJwYLpf2ZUA{width:20px;height:20px;border-width:2px}.fIKsKVtFL9GEdaaWag8e{width:40px;height:40px;border-width:4px}.ZocL5GKbxomOLXk4Nlf_{width:60px;height:60px;border-width:6px}@keyframes R4iNuFBGscQgShxbup4A{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/spinner/spinner.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CACA,iBAAA,CACA,4BAAA,CACA,UAAA,CACA,WAAA,CACA,iDAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,gCACE,GAAA,sBAAA,CACA,KAAA,wBAAA,CAAA","sourcesContent":[".spinner {\n  border: 4px solid rgba(255, 255, 255, 0.3);\n  border-radius: 50%;\n  border-top: 4px solid #158dd8;\n  width: 40px;\n  height: 40px;\n  animation: spin 1s linear infinite;\n}\n\n.small {\n  width: 20px;\n  height: 20px;\n  border-width: 2px;\n}\n\n.medium {\n  width: 40px;\n  height: 40px;\n  border-width: 4px;\n}\n\n.large {\n  width: 60px;\n  height: 60px;\n  border-width: 6px;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `er9MWdyAbN_AKbdNkT0v`,
	"spin": `R4iNuFBGscQgShxbup4A`,
	"small": `ipntgEPiPwJwYLpf2ZUA`,
	"medium": `fIKsKVtFL9GEdaaWag8e`,
	"large": `ZocL5GKbxomOLXk4Nlf_`
};
export default ___CSS_LOADER_EXPORT___;
