import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { userState } from '../../features/user/model';
import * as oopsSticker from '../../shared/lib/images/stickers/oops.json';
import s from './something-went-wrong.module.scss';
export const SomethingWentWrong = ({}) => {
    const isMobile = false;
    const { isError } = useUnit(userState);
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: oopsSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const handleButton = () => window.location.reload();
    useEffect(() => {
        if (!isError)
            return;
        WebAppSDK.MainButton.show();
        WebAppSDK.MainButton.enable();
        WebAppSDK.MainButton.text = t('buttons.reload');
        WebAppSDK.MainButton.onClick(handleButton);
        WebAppSDK.BackButton.hide();
        return () => WebAppSDK.MainButton.offClick(handleButton);
    }, [isError]);
    return (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.innerImg, children: _jsx(Lottie, { options: approveOptions, height: 200, isClickToPauseDisabled: true, width: 200 }) }), _jsx("h1", { className: `${s.innerTitle} ${s.innerTitleRed}`, children: t('oops.oops') }), _jsx("h2", { className: s.innerText, children: t('oops.issues2') })] }));
};
