import { jsx as _jsx } from "react/jsx-runtime";
import Level1 from '../../shared/lib/images/steps/level1.png';
import Level2 from '../../shared/lib/images/steps/level2.png';
import Level3 from '../../shared/lib/images/steps/level3.png';
import Level4 from '../../shared/lib/images/steps/level4.png';
import Level5 from '../../shared/lib/images/steps/level5.png';
import Level6 from '../../shared/lib/images/steps/level6.png';
import Level7 from '../../shared/lib/images/steps/level7.png';
import Level8 from '../../shared/lib/images/steps/level8.png';
import Level9 from '../../shared/lib/images/steps/level9.png';
import Level10 from '../../shared/lib/images/steps/level10.png';
import Level11 from '../../shared/lib/images/steps/level11.png';
import Level12 from '../../shared/lib/images/steps/level12.png';
import Level13 from '../../shared/lib/images/steps/level13.png';
import Level14 from '../../shared/lib/images/steps/level14.png';
import Level15 from '../../shared/lib/images/steps/level15.png';
import Level16 from '../../shared/lib/images/steps/level16.png';
import Level17 from '../../shared/lib/images/steps/level17.png';
import Level18 from '../../shared/lib/images/steps/level18.png';
import Level19 from '../../shared/lib/images/steps/level19.png';
import Level20 from '../../shared/lib/images/steps/level20.png';
import Level21 from '../../shared/lib/images/steps/level21.png';
import Level22 from '../../shared/lib/images/steps/level22.png';
import Level23 from '../../shared/lib/images/steps/level23.png';
import Level24 from '../../shared/lib/images/steps/level24.png';
import Level25 from '../../shared/lib/images/steps/level25.png';
import Level26 from '../../shared/lib/images/steps/level26.png';
import Level27 from '../../shared/lib/images/steps/level27.png';
import Level28 from '../../shared/lib/images/steps/level28.png';
import Level29 from '../../shared/lib/images/steps/level29.png';
import Level30 from '../../shared/lib/images/steps/level30.png';
export const getLevelComponent = (computerId) => {
    switch (computerId) {
        case 1:
            return _jsx("img", { src: Level1, alt: "level 1" });
        case 2:
            return _jsx("img", { src: Level2, alt: "level 2" });
        case 3:
            return _jsx("img", { src: Level3, alt: "level 3" });
        case 4:
            return _jsx("img", { src: Level4, alt: "level 4" });
        case 5:
            return _jsx("img", { src: Level5, alt: "level 5" });
        case 6:
            return _jsx("img", { src: Level6, alt: "level 6" });
        case 7:
            return _jsx("img", { src: Level7, alt: "level 7" });
        case 8:
            return _jsx("img", { src: Level8, alt: "level 8" });
        case 9:
            return _jsx("img", { src: Level9, alt: "level 9" });
        case 10:
            return _jsx("img", { src: Level10, alt: "level 10" });
        case 11:
            return _jsx("img", { src: Level11, alt: "level 11" });
        case 12:
            return _jsx("img", { src: Level12, alt: "level 12" });
        case 13:
            return _jsx("img", { src: Level13, alt: "level 13" });
        case 14:
            return _jsx("img", { src: Level14, alt: "level 14" });
        case 15:
            return _jsx("img", { src: Level15, alt: "level 15" });
        case 16:
            return _jsx("img", { src: Level16, alt: "level 16" });
        case 17:
            return _jsx("img", { src: Level17, alt: "level 17" });
        case 18:
            return _jsx("img", { src: Level18, alt: "level 18" });
        case 19:
            return _jsx("img", { src: Level19, alt: "level 19" });
        case 20:
            return _jsx("img", { src: Level20, alt: "level 20" });
        case 21:
            return _jsx("img", { src: Level21, alt: "level 21" });
        case 22:
            return _jsx("img", { src: Level22, alt: "level 22" });
        case 23:
            return _jsx("img", { src: Level23, alt: "level 23" });
        case 24:
            return _jsx("img", { src: Level24, alt: "level 24" });
        case 25:
            return _jsx("img", { src: Level25, alt: "level 25" });
        case 26:
            return _jsx("img", { src: Level26, alt: "level 26" });
        case 27:
            return _jsx("img", { src: Level27, alt: "level 27" });
        case 28:
            return _jsx("img", { src: Level28, alt: "level 28" });
        case 29:
            return _jsx("img", { src: Level29, alt: "level 29" });
        case 30:
            return _jsx("img", { src: Level30, alt: "level 30" });
        default:
            return _jsx("img", { src: Level1, alt: "level" });
    }
};
