// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G31W55i8PEzuNromxh58{display:flex;align-items:center;justify-content:space-between;gap:20px;margin-bottom:12px}.PR3moBu6uQ3ZDxR1sL3e{font-size:20px;font-weight:700}.ehGnaSdX8nsNxqnGXG5D{display:flex;align-items:center;gap:5px;font-size:16px;font-weight:600;color:#3495ff;cursor:pointer;transition:all .3s ease 0s}.ehGnaSdX8nsNxqnGXG5D:hover{color:#0276f2;opacity:.9}.H1MHGD9kSpmlLGhbaB8U{padding:18px 5px;border-radius:16px;background:rgba(33,52,69,.8)}.H1MHGD9kSpmlLGhbaB8U:not(:last-child){margin-bottom:15px}.baB_Ej7VMjHAvctq2YlG{opacity:.3}`, "",{"version":3,"sources":["webpack://./src/widgets/cases/ui/case-list/case-list.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CACA,0BAAA,CACA,4BACE,aAAA,CACA,UAAA,CAIJ,sBACE,gBAAA,CACA,kBAAA,CACA,4BAAA,CACA,uCACE,kBAAA,CAIJ,sBACE,UAAA","sourcesContent":[".boostsTop {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n  margin-bottom: 12px;\n}\n\n.boostsTitle {\n  font-size: 20px;\n  font-weight: 700;\n}\n\n.boostsHistory {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  font-size: 16px;\n  font-weight: 600;\n  color: #3495FF;\n  cursor: pointer;\n  transition: all 0.3s ease 0s;\n  &:hover {\n    color: #0276f2;\n    opacity: 0.9;\n  }\n}\n\n.boostsLevels {\n  padding: 18px 5px;\n  border-radius: 16px;\n  background: rgba(33, 52, 69, 0.80);\n  &:not(:last-child) {\n    margin-bottom: 15px;\n  }\n}\n\n.boostsLevelBuyed {\n  opacity: 0.3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boostsTop": `G31W55i8PEzuNromxh58`,
	"boostsTitle": `PR3moBu6uQ3ZDxR1sL3e`,
	"boostsHistory": `ehGnaSdX8nsNxqnGXG5D`,
	"boostsLevels": `H1MHGD9kSpmlLGhbaB8U`,
	"boostsLevelBuyed": `baB_Ej7VMjHAvctq2YlG`
};
export default ___CSS_LOADER_EXPORT___;
