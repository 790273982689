// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l0cgJk7ubEHFdukaRCEz{position:relative;z-index:301}.tDj7JGvTS22A2QfVPOuz{position:relative;z-index:500}.RR1dkbEGkjC5FSA8In2s{display:flex;align-items:center;justify-content:center;flex-direction:column;margin-bottom:32px}.wIn214Svj5ytyRRbQGb4{color:var(--tg-theme-button-color);margin-top:5px;transition:all .3s ease 0s}.wIn214Svj5ytyRRbQGb4:hover{text-decoration:underline}.S7_0Ukj6AEQ2zb1_H63Z{font-size:20px;font-weight:700;margin:20px 0 12px 0}.NFGvZ8dxqQ2uLlTX6DTu{margin-top:-40px}.AvLcQe20VXVlVZ9iEaiC{opacity:.3}.TV_gIzppROIO11nsKkA_{opacity:.5}`, "",{"version":3,"sources":["webpack://./src/pages/boosts/boosts.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CAIA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CAEF,sBACE,kCAAA,CACA,cAAA,CACA,0BAAA,CACA,4BACE,yBAAA,CAGJ,sBACE,cAAA,CACA,eAAA,CACA,oBAAA,CACA,sBACE,gBAAA,CAGJ,sBACE,UAAA,CAKJ,sBACE,UAAA","sourcesContent":[".wrapper {\n  position: relative;\n  z-index: 301;\n}\n\n.wrapper2 {\n  position: relative;\n  z-index: 500;\n}\n\n.boosts {\n  &Top {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    margin-bottom: 32px;\n  }\n  &Link {\n    color: var(--tg-theme-button-color);\n    margin-top: 5px;\n    transition: all 0.3s ease 0s;\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n  &Title {\n    font-size: 20px;\n    font-weight: 700;\n    margin: 20px 0 12px 0;\n    &Earn {\n      margin-top: -40px;\n    }\n  }\n  &LevelBuyed {\n    opacity: 0.3;\n  }\n}\n\n\n.completedTask {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `l0cgJk7ubEHFdukaRCEz`,
	"wrapper2": `tDj7JGvTS22A2QfVPOuz`,
	"boostsTop": `RR1dkbEGkjC5FSA8In2s`,
	"boostsLink": `wIn214Svj5ytyRRbQGb4`,
	"boostsTitle": `S7_0Ukj6AEQ2zb1_H63Z`,
	"boostsTitleEarn": `NFGvZ8dxqQ2uLlTX6DTu`,
	"boostsLevelBuyed": `AvLcQe20VXVlVZ9iEaiC`,
	"completedTask": `TV_gIzppROIO11nsKkA_`
};
export default ___CSS_LOADER_EXPORT___;
