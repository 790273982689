import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class UserApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async getUser(id) {
        return this.apiService.get('user', { referralId: id });
    }
}
