import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class FriendsApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async getUserFriends() {
        return this.apiService.get('user/friends', {});
    }
}
