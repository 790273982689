import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import WebAppSDK from '@twa-dev/sdk';
import { handleLevel, levelDetails, setShowLevelDetails, showLevelsDetails } from '../../../../features/level/model';
import { Modal } from '../../../../shared/ui/components';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import BG from '../../../../shared/lib/images/background.png';
import s from './level-detail.module.scss';
export const LevelDetail = ({ user, onClick }) => {
    const levelDetailsData = useUnit(levelDetails);
    const showLevelDetailsState = useUnit(showLevelsDetails);
    const TgObj = WebAppSDK;
    const { t } = useTranslation();
    if (!levelDetailsData)
        return _jsx(_Fragment, {});
    const handleCloseModal = () => {
        setShowLevelDetails(false);
        handleLevel(null);
        TgObj.MainButton.hide();
    };
    const handleClick = () => {
        if (user && user?.computerId) {
            if (user?.computerId === levelDetailsData.level)
                return;
            onClick((user?.computerId ?? 0) + 1);
        }
    };
    const balance = user?.balance ? user?.balance : 0;
    useEffect(() => {
        if (!user) {
            TgObj.MainButton.disable();
            TgObj.MainButton.color = '#666';
            return;
        }
        if (user?.balance >= levelDetailsData.amount) {
            TgObj.MainButton.enable();
            TgObj.MainButton.color = '#007AFF';
            TgObj.MainButton.onClick(handleClick);
        }
        else {
            TgObj.MainButton.disable();
            TgObj.MainButton.color = '#666';
        }
        return () => TgObj.MainButton.offClick(handleClick);
    }, [levelDetailsData, user]);
    const formattedAmount = levelDetailsData?.amount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const displayAmount = formattedAmount.endsWith('.00') ? formattedAmount?.slice(0, -3) : formattedAmount;
    return (_jsx("div", { className: s.wrapper, children: _jsxs(Modal, { isOpen: showLevelDetailsState, onClose: handleCloseModal, isCloseButton: false, className: s.modal, children: [_jsx("img", { src: BG, className: s.modalBg, alt: "bg" }), _jsx("div", { className: s.modalTop, children: _jsx("button", { className: s.modalTopButton, onClick: handleCloseModal, children: _jsx(SvgSelector, { id: "close" }) }) }), _jsx("img", { className: s.modalPreview, src: levelDetailsData.preview_img, alt: "preview" }), _jsxs("h2", { className: s.modalTitle, children: [t('common.level'), " ", levelDetailsData.level] }), _jsx("p", { className: s.modalDescription, children: levelDetailsData.description }), _jsxs("div", { className: s.modalAmount, children: [_jsx(SvgSelector, { id: "coin-small" }), displayAmount] })] }) }));
};
