import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import WebAppSDK from '@twa-dev/sdk';
import { DailyBonusApi } from '../../entities/daily-bonus';
import { getUserData, userState } from '../../features/user/model';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import DailyLogo from '../../shared/lib/images/daily-boosts/logo.png';
import s from './daily-bonus.module.scss';
import { formatNumber } from '../../shared/utils/formatNumber';
export const DailyBonus = () => {
    const [isDailyActive, setDailyActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const TgObj = WebAppSDK;
    const { user } = useUnit(userState);
    const dailyBonusApi = new DailyBonusApi();
    const handleActivateBoost = async () => {
        if (!isDailyActive)
            return;
        if (isLoading)
            return;
        try {
            setIsLoading(true);
            const res = await dailyBonusApi.postDailyBonus();
            if (res) {
                TgObj.MainButton.hide();
                TgObj.showAlert(t('boosts.alert-bonus'));
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            getUserData({ shouldSetLoading: false });
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (!user) {
            return;
        }
        if (!user?.lastBonusRecieved) {
            setDailyActive(true);
            return;
        }
        const timestamp = parseInt(user.lastBonusRecieved, 10);
        const storedDate = new Date(timestamp);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - storedDate.getTime();
        const hoursInMilliseconds = 24 * 60 * 60 * 1000;
        const hasPassed = timeDifference >= hoursInMilliseconds;
        if (hasPassed) {
            setDailyActive(true);
        }
        else {
            setDailyActive(false);
        }
    }, [user]);
    return _jsx(_Fragment, { children: isDailyActive && (_jsxs(_Fragment, { children: [_jsx("div", { className: s.boostsTitle, children: t('boosts.bonus-title') }), _jsxs("div", { className: `${s.boostsDaily} ${isLoading ? s.boostsLevelBuyed : ''}`, onClick: handleActivateBoost, children: [_jsx("img", { src: DailyLogo, alt: "daily logo" }), _jsxs("div", { className: s.boostsDailyRight, children: [_jsxs("div", { className: s.boostsDailyTop, children: [_jsxs("div", { className: s.boostsDailyTopInfo, children: [_jsx("span", { children: formatNumber(30000) }), " ", t('common.secret-coins')] }), ' ', _jsx(SvgSelector, { id: "chevron-right" })] }), isDailyActive ? (_jsx("div", { className: s.boostsDailyDescription, children: t('common.you-can-get') })) : ('')] })] })] })) });
};
