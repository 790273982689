/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unneeded-ternary */
import { combine, createEvent, createStore } from 'effector';
import { TopApiService } from '../../entities/top';
export const setHackers = createEvent();
export const hackersStore = createStore(null)
    .on(setHackers, (_, newData) => newData);
export const hackerLoadingStore = createStore(false);
export const setHackersLoading = createEvent();
hackerLoadingStore.on(setHackersLoading, (_, isLoading) => isLoading);
export const setSquads = createEvent();
export const squadsStore = createStore(null)
    .on(setSquads, (_, newData) => newData);
export const squadsLoadingStore = createStore(false);
export const setSquadsLoading = createEvent();
squadsLoadingStore.on(setSquadsLoading, (_, isLoading) => isLoading);
export const getTop = createEvent();
const topApiService = new TopApiService();
getTop.watch(async () => {
    try {
        setHackersLoading(true);
        setSquadsLoading(true);
        const res = await topApiService.getTop();
        setHackers(res.hackers);
        setSquads(res.squads);
    }
    catch (err) {
        console.error(err);
    }
    finally {
        setSquadsLoading(false);
        setHackersLoading(false);
    }
});
export const setShowGroups = createEvent();
const initialGroupsState = JSON.parse(localStorage.getItem('groupsState') || 'false');
export const showGroupsStore = createStore(initialGroupsState).on(setShowGroups, state => !state);
showGroupsStore.watch((newState) => {
    localStorage.setItem('groupsState', JSON.stringify(newState));
});
export const topState = combine({
    hackers: hackersStore,
    squads: squadsStore,
    hackersLoading: hackerLoadingStore,
    squadsLoading: squadsLoadingStore,
    showGroups: showGroupsStore
});
