import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { languageStore, toggleLanguage } from '../features/language/model';
import { getUserData, setIsError, userState } from '../features/user/model';
import { sharedConfigRoutes } from '../shared/config';
import { Home } from './home';
import { Friends } from './friends';
import { Boosts } from './boosts';
import { Profile } from './profile';
import { Squads } from './squads';
import { Top } from './top';
import { SquadsDetail } from './squads-detail';
import { Task } from './task';
import { Buy } from './buy';
import { SomethingWentWrong } from './something-went-wrong';
import { clicksState, setAwardsClicks, setUserEnergyRemain } from '../features/click/model';
import { MainLayout } from '../shared/ui/layouts';
import { SpinnerScreen } from '../shared/ui/components';
import { socket } from '../shared/utils/socket';
import '../shared/utils/i18n';
const { RouteName } = sharedConfigRoutes;
const { BOOSTS_PAGE, BUY_PAGE, EARN_PAGE, FRIENDS_PAGE, HOME_PAGE, SQUADS_PAGE, SQUAD_DETAIL_PAGE, TASK_PAGE, TOP_PAGE, SOMETHING_WENT_WRONG_PAGE } = RouteName;
const routes = [
    {
        path: HOME_PAGE,
        component: Home
    },
    {
        path: FRIENDS_PAGE,
        component: Friends
    },
    {
        path: BOOSTS_PAGE,
        component: Boosts
    },
    {
        path: EARN_PAGE,
        component: Profile
    },
    {
        path: SQUADS_PAGE,
        component: Squads
    },
    {
        path: TOP_PAGE,
        component: Top
    },
    {
        path: SQUAD_DETAIL_PAGE,
        component: SquadsDetail
    },
    {
        path: TASK_PAGE,
        component: Task
    },
    {
        path: BUY_PAGE,
        component: Buy
    },
    {
        path: SOMETHING_WENT_WRONG_PAGE,
        component: SomethingWentWrong
    }
];
const routesContent = routes.map(({ path, component: Component }) => (_jsx(Route, { path: path, element: _jsx(Component, {}) }, path)));
export const Routing = () => {
    const navigate = useNavigate();
    const { isLoading, user } = useUnit(userState);
    useEffect(() => {
        const isTgCheck = window.Telegram.WebApp.initData !== '';
        if (isTgCheck) {
            WebAppSDK.ready();
            WebAppSDK.enableClosingConfirmation();
            WebAppSDK.expand();
            WebAppSDK.setHeaderColor('#1e2124');
            const search = window.location.search;
            const searchParams = new URLSearchParams(search);
            const id = searchParams.get('tgWebAppStartParam');
            if (id && id?.startsWith('guild_')) {
                const guildIdWithoutPrefix = id?.slice('guild_'.length);
                navigate(`/squad-detail/${guildIdWithoutPrefix}`);
            }
            const refId = id?.startsWith('ref_') ? id?.slice('ref_'.length) : undefined;
            getUserData({ id: refId, shouldSetLoading: true });
        }
        else {
            navigate(SOMETHING_WENT_WRONG_PAGE);
            setIsError(true);
        }
    }, []);
    //= =======================================================================================================================================================
    const savedLanguage = localStorage.getItem('i18nextLng');
    const currLang = useUnit(languageStore);
    const { i18n } = useTranslation();
    useEffect(() => {
        const initializeLanguage = async () => {
            const TgLanguage = WebAppSDK?.initDataUnsafe?.user?.language_code;
            let language;
            const userDefinedLanguage = localStorage.getItem('i18nextLngOwn');
            if (userDefinedLanguage) {
                language = userDefinedLanguage;
            }
            else if (TgLanguage) {
                const lowerCaseTgLanguage = TgLanguage.toLowerCase();
                if (lowerCaseTgLanguage === 'ru' || lowerCaseTgLanguage === 'en') {
                    language = lowerCaseTgLanguage;
                }
                else {
                    language = 'en';
                }
            }
            else {
                language = 'en';
            }
            toggleLanguage(language);
        };
        if (!savedLanguage) {
            initializeLanguage();
        }
    }, []);
    useEffect(() => {
        i18n.changeLanguage(currLang ?? 'en');
        localStorage.setItem('i18nextLng', currLang ?? 'en');
    }, [i18n, currLang]);
    //= =======================================================================================================================================================
    const location = useLocation();
    const { clicks, awardsClicks, userEnergyRemain } = useUnit(clicksState);
    useEffect(() => {
        socket.on('connection', (res) => { });
        socket.on('TAP_UPDATE', (res) => {
            setUserEnergyRemain(res.energy ?? 0);
        });
        return () => {
            socket.off('connect');
        };
    }, []);
    useEffect(() => {
        if (location.pathname !== '/')
            return;
        const intervalId2 = setInterval(() => {
            if (user?.computer && user?.computer.energy > userEnergyRemain) {
                setUserEnergyRemain(userEnergyRemain + 1);
            }
        }, 1000);
        return () => {
            clearInterval(intervalId2);
        };
    }, [user, userEnergyRemain, location]);
    useEffect(() => {
        if (awardsClicks >= 100) {
            const claimAwards = () => {
                setAwardsClicks(0);
            };
            claimAwards();
        }
    }, [awardsClicks]);
    //= =======================================================================================================================================================
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(SpinnerScreen, {})) : (_jsx(MainLayout, { children: _jsx(Routes, { children: routesContent }) })) }));
};
