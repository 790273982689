export const menuMocks = [
    {
        id: 14124,
        path: '/',
        icon: 'home',
        name: 'navigation.home',
        iconActive: 'home-active'
    },
    {
        id: 15677,
        path: '/friends',
        icon: 'friends',
        name: 'navigation.friends',
        iconActive: 'friends-active'
    },
    {
        id: 12345,
        path: '/boosts',
        icon: 'boosts',
        name: 'navigation.boosts',
        iconActive: 'boosts-active'
    }
];
