import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class TopApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async getTop() {
        return this.apiService.get('rating', {});
    }
}
