/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function formatNumber(num) {
    if (num >= 1e21) {
        return `${(num / 1e21)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}Z`;
    }
    if (num >= 1e18) {
        return `${(num / 1e18)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}E`;
    }
    if (num >= 1e15) {
        return `${(num / 1e15)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}P`;
    }
    if (num >= 1e12) {
        return `${(num / 1e12)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}T`;
    }
    if (num >= 1e9) {
        return `${(num / 1e9)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}B`;
    }
    if (num >= 1e6) {
        return `${(num / 1e6)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}M`;
    }
    if (num >= 1e3) {
        return `${(num / 1e3)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}K`;
    }
    return num?.toLocaleString();
}
