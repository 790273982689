import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class CaseApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async buyBox(id) {
        return this.apiService.post(`case/buy?caseId=${id}`, {});
    }
    async getCases() {
        return this.apiService.get('case', {});
    }
    async getCaseDetail(id) {
        return this.apiService.get(`case/info?caseId=${id}`, {});
    }
}
