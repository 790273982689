/* eslint-disable import/no-extraneous-dependencies */
import { createEvent, createStore, combine } from 'effector';
import { CaseApiService } from '../../entities/case';
export const setCaseList = createEvent();
export const caseListStore = createStore(null)
    .on(setCaseList, (_, newCase) => newCase);
export const isErrorStore = createStore(false);
export const setIsError = createEvent();
isErrorStore.on(setIsError, (_, isError) => isError);
export const isLoadingStore = createStore(false);
export const setIsLoading = createEvent();
isLoadingStore.on(setIsLoading, (_, isLoading) => isLoading);
export const getCaseList = createEvent();
const caseApiService = new CaseApiService();
getCaseList.watch(async () => {
    try {
        setIsLoading(true);
        const res = await caseApiService.getCases();
        setCaseList(res);
        setIsError(false);
    }
    catch (error) {
        console.error('Error fetch cases:', error);
        setIsError(true);
    }
    finally {
        setIsLoading(false);
    }
});
export const casesState = combine({
    caseList: caseListStore,
    isError: isErrorStore,
    isLoading: isLoadingStore
});
export const getCaseDetail = createEvent();
export const setCaseDetail = createEvent();
export const caseDetailStore = createStore(null)
    .on(setCaseList, (_, newCase) => newCase);
export const isLoadingDetailStore = createStore(false);
export const setIsLoadingDetail = createEvent();
getCaseDetail.watch(async ({ caseId }) => {
    try {
        setIsLoadingDetail(true);
        const res = await caseApiService.getCaseDetail(caseId);
        setCaseDetail(res);
    }
    catch (error) {
        console.error('Error initializing user:', error);
    }
    finally {
        setIsLoadingDetail(false);
    }
});
export const caseDetailState = combine({
    caseDetail: caseDetailStore,
    isLoadingDetail: isLoadingDetailStore
});
