import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import WebAppSDK from '@twa-dev/sdk';
import s from './modal.module.scss';
export const Modal = ({ children, onClose, isOpen, className, buttonClass, isCloseButton = true }) => {
    const TgObj = WebAppSDK;
    const modalRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);
    const handleCloseModal = () => {
        TgObj.HapticFeedback.impactOccurred('medium');
        setIsClosing(true);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && isClosing) {
                handleCloseModal();
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose, isClosing]);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);
    return (_jsx(AnimatePresence, { onExitComplete: onClose, children: isOpen && (_jsx(motion.div, { className: s.modalBackdrop, transition: {
                duration: 0.3,
                bounce: 0
            }, children: _jsxs(motion.div, { className: `${s.modalContent} ${className || ''}`, ref: modalRef, initial: { y: '100%' }, animate: { y: 0 }, exit: { y: '100%' }, transition: {
                    duration: 0.3,
                    bounce: 0,
                    type: 'spring'
                }, children: [isCloseButton && (_jsx("button", { className: `${s.closeButton} ${buttonClass || ''}`, onClick: handleCloseModal, children: "\u00D7" })), children] }) })) }));
};
