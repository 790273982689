import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import { menuMocks } from '../../../../shared/mock/menu-actions';
import { socket } from '../../../../shared/utils/socket';
import { clicksState, setClicks } from '../../../../features/click/model';
import { setShowHistory } from '../../../../features/history/model';
import s from './main-actions.module.scss';
export const MainActions = () => {
    const path = window.location.pathname;
    const { clicks } = useUnit(clicksState);
    const [activeLink, setActiveLink] = useState(path);
    const TgObj = WebAppSDK;
    const { t } = useTranslation();
    const sendClickToServer = () => {
        socket.emit('tap', { count: clicks });
        setClicks(0);
    };
    useEffect(() => {
        if (path === '/buy') {
            setShowHistory(false);
            setActiveLink('');
        }
    }, [path]);
    return (_jsxs("nav", { className: s.menu, children: [menuMocks.map(el => (_jsx(Link, { to: el.path, children: _jsxs("button", { className: `${s.menuButton} ${activeLink === el.path ? s.activeLink : ''}`, onClick: () => {
                        setShowHistory(false);
                        setActiveLink(el.path);
                        window.scrollTo(0, 0);
                        WebAppSDK.BackButton.hide();
                        sendClickToServer();
                        activeLink === '/' && WebAppSDK.MainButton.hide();
                    }, children: [activeLink === el.path ? _jsx(SvgSelector, { id: el.iconActive }) : _jsx(SvgSelector, { id: el.icon }), _jsx("span", { children: t(el.name) })] }) }, `pc-el-${el.id}`))), _jsx(Link, { to: '/profile', children: _jsxs("button", { className: `${s.menuButton} ${activeLink === '/profile' ? s.activeLink : ''}`, onClick: () => {
                        setShowHistory(false);
                        setActiveLink('/profile');
                        window.scrollTo(0, 0);
                        sendClickToServer();
                        WebAppSDK.BackButton.hide();
                        activeLink === '/' && WebAppSDK.MainButton.hide();
                    }, children: [_jsx("div", { style: { position: 'relative' }, children: _jsxs("div", { className: s.menuAvatar, children: [_jsx("img", { src: `https://t.me/i/userpic/320/${TgObj?.initDataUnsafe?.user?.username}.jpg`, onLoad: (e) => {
                                            const target = e.currentTarget;
                                            if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                                                target.style.display = 'none';
                                                TgObj.initDataUnsafe?.user?.username?.slice(0, 2)?.toUpperCase();
                                            }
                                        }, style: { zIndex: '5' } }), _jsx("div", { style: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, children: activeLink === '/profile' ? _jsx(SvgSelector, { id: "profile-active" }) : _jsx(SvgSelector, { id: "profile-active" }) })] }) }), _jsx("span", { children: t('navigation.profile') })] }) })] }));
};
