import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import WebApp from '@twa-dev/sdk';
import { languageStore, toggleLanguage } from '../../features/language/model';
import { Balance } from '../../shared/ui/components/balance';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import s from './profile-top.module.scss';
export const ProfileTop = () => {
    const { t } = useTranslation();
    const currLang = useUnit(languageStore);
    const TgObj = WebApp;
    return (_jsxs("div", { className: s.profileTop, children: [_jsx("button", { className: s.support, onClick: () => { window.open('https://t.me/delabsupportbot', '_blank'); }, children: _jsx(SvgSelector, { id: "support" }) }), _jsxs("div", { className: s.profileTopAvatar, children: [_jsx("img", { src: `https://t.me/i/userpic/320/${TgObj?.initDataUnsafe?.user?.username}.jpg`, onLoad: (e) => {
                            const target = e.currentTarget;
                            if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                                target.style.display = 'none';
                                TgObj?.initDataUnsafe?.user?.username?.slice(0, 2)?.toUpperCase();
                            }
                        }, style: { zIndex: '5' } }), _jsx("div", { style: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, children: TgObj?.initDataUnsafe?.user?.username?.slice(0, 2)?.toUpperCase() || 'EL' })] }), _jsxs("div", { className: s.profileTopData, children: [_jsx("div", { children: TgObj?.initDataUnsafe?.user?.first_name }), TgObj?.initDataUnsafe?.user?.last_name && (_jsx("div", { children: TgObj?.initDataUnsafe?.user?.last_name }))] }), _jsx(Balance, { prevLoc: '/profile' }), _jsx("button", { className: s.language, onClick: () => toggleLanguage(currLang === 'ru' ? 'en' : 'ru'), children: currLang === 'ru' ? (_jsx(ReactCountryFlag, { countryCode: "ru", style: {
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                    }, svg: true })) : (_jsx(ReactCountryFlag, { countryCode: "us", style: {
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                    }, svg: true })) })] }));
};
