// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lJ5Xn2gzESjOAc3MQoSy{display:flex;align-items:center;justify-content:center;flex-direction:column;margin-top:-10px;margin-bottom:10px;padding:0 32px}.uE0mD5Q8Bws1YTXo1Htf{display:flex;align-items:center;gap:15px;width:100%;margin-top:5px}.vDUNErF41YQWdPiitmHl{font-size:21px;font-weight:600;min-width:max-content;font-family:sans-serif}.rS33MWeUdQuGhNc3PDNc{width:100%;height:21px;border-radius:6px;background:rgba(255,255,255,.1);padding:3px}.hfg3EMTB1LrKfwrz4jA8{height:100%;background:linear-gradient(270deg, #78b8ff 0%, #007aff 100%);border-radius:4px}`, "",{"version":3,"sources":["webpack://./src/shared/ui/components/stats/stats.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,gBAAA,CACA,kBAAA,CACA,cAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CACA,sBACE,cAAA,CACA,eAAA,CACA,qBAAA,CACA,sBAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,+BAAA,CACA,WAAA,CAEF,sBACE,WAAA,CACA,4DAAA,CACA,iBAAA","sourcesContent":[".stats {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-top: -10px;\n  margin-bottom: 10px;\n  padding: 0 32px;\n  &Progress {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    width: 100%;\n    margin-top: 5px;\n    &Info {\n      font-size: 21px;\n      font-weight: 600;\n      min-width: max-content;\n      font-family: sans-serif;\n    }\n    &Bar {\n      width: 100%;\n      height: 21px;\n      border-radius: 6px;\n      background: rgba(255, 255, 255, 0.1);\n      padding: 3px;\n    }\n    &Line {\n      height: 100%;\n      background: linear-gradient(270deg, #78b8ff 0%, #007aff 100%);\n      border-radius: 4px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats": `lJ5Xn2gzESjOAc3MQoSy`,
	"statsProgress": `uE0mD5Q8Bws1YTXo1Htf`,
	"statsProgressInfo": `vDUNErF41YQWdPiitmHl`,
	"statsProgressBar": `rS33MWeUdQuGhNc3PDNc`,
	"statsProgressLine": `hfg3EMTB1LrKfwrz4jA8`
};
export default ___CSS_LOADER_EXPORT___;
