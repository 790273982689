import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { userState } from '../../../../features/user/model';
import { SomethingWentWrong } from '../../../../pages/something-went-wrong';
import ARBUZ_BG from '../../../lib/images/skins/arbuz-bg.png';
import VR_BG from '../../../lib/images/skins/vr-bg.png';
import LOPATA_BG from '../../../lib/images/skins/lopata-bg.png';
import { showHistory } from '../../../../features/history/model';
import { showLevelsDetails } from '../../../../features/level/model';
import { showBoxDetails } from '../../../../features/box/model';
import { showSkin } from '../../../../features/skin/model';
import { MainActions } from '../../../../widgets/main-actions/ui/main-actions';
import BG from '../../../lib/images/background.png';
import BG2 from '../../../lib/images/background-green.png';
import s from './main-layout.module.scss';
export const MainLayout = ({ children }) => {
    const location = useLocation();
    const showHistoryState = useUnit(showHistory);
    const showLevelsDetailsState = useUnit(showLevelsDetails);
    const showBoxDetailsState = useUnit(showBoxDetails);
    const showSkinState = useUnit(showSkin);
    const { user, isError } = useUnit(userState);
    const wrapperRef = useRef(null);
    useEffect(() => {
        let backgroundImage;
        if (location.pathname !== '/squads' && !location.pathname.startsWith('/squad-detail/')) {
            if (location.pathname === '/') {
                switch (user?.skinId) {
                    case 1:
                        backgroundImage = BG;
                        break;
                    case 2:
                        backgroundImage = LOPATA_BG;
                        break;
                    case 3:
                        backgroundImage = VR_BG;
                        break;
                    case 4:
                        backgroundImage = ARBUZ_BG;
                        break;
                    default:
                        backgroundImage = BG;
                }
            }
            else {
                backgroundImage = BG;
            }
        }
        else {
            backgroundImage = BG2;
        }
        document.body.style.backgroundImage = `url(${backgroundImage})`;
        return () => {
            document.body.style.backgroundImage = '';
        };
    }, [location.pathname, user?.skinId]);
    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollTo(0, 0);
        }
    }, [showHistoryState, location.pathname]);
    if (isError) {
        return (_jsx("div", { className: s.wrapper, style: { height: '100%' }, children: _jsx("div", { className: s.wrapperContent, style: { paddingBottom: '0px' }, children: _jsx(SomethingWentWrong, {}) }) }));
    }
    return (_jsxs("div", { className: s.wrapper, children: [_jsx("div", { className: s.wrapperContent, style: {
                    height: ` ${location.pathname !== '/squads'
                        && location.pathname !== '/top'
                        && !location.pathname.startsWith('/squad-detail/')
                        && !location.pathname.startsWith('/task/')
                        && !showLevelsDetailsState
                        && !showBoxDetailsState
                        && !showSkinState
                        ? 'calc(100% - 100px)'
                        : '100%'}`,
                    overflowY: showLevelsDetailsState || showBoxDetailsState || showSkinState ? 'hidden' : 'auto',
                    overflowX: 'hidden'
                }, ref: wrapperRef, children: children }), location.pathname !== '/squads'
                && !location.pathname.startsWith('/squad-detail/')
                && location.pathname !== '/top'
                && !location.pathname.startsWith('/task/') && (_jsx("div", { className: s.actions, children: _jsx(MainActions, {}) }))] }));
};
