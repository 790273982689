import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { ProfileTop } from '../../widgets/profile-top';
import { DailyBonus } from '../../widgets/daily-bonus';
import { CaseList } from '../../widgets/cases/ui';
import { HistoryList } from '../../widgets/history/ui/history-list';
import { showHistory } from '../../features/history/model';
import { Alert } from '../../shared/ui/components';
import s from './profile.module.scss';
export const Profile = () => {
    const showHistoryState = useUnit(showHistory);
    const [isCopiedLink, setIsCopiedLink] = useState(false);
    const { t } = useTranslation();
    const TgObj = WebAppSDK;
    return (_jsx(_Fragment, { children: showHistoryState ? (_jsx(HistoryList, {})) : (_jsxs("div", { className: s.profile, children: [_jsx(ProfileTop, {}), _jsx(DailyBonus, {}), _jsx(CaseList, {}), isCopiedLink && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedLink(false), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", className: s.alert, children: t('friends.alert') }))] })) }));
};
