import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { handleLevel, setShowLevelDetails } from '../../../../features/level/model';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import s from './level.module.scss';
import { formatNumber } from '../../../../shared/utils/formatNumber';
export const Level = ({ levelData, user }) => {
    const { t } = useTranslation();
    // @ts-ignore
    const { level, amount, description, preview_img, preview_description_clicks } = levelData;
    const isUnlocked = level === 1 || (user?.computerId && user?.computerId >= level - 1);
    const isBought = isUnlocked && (user?.computerId ?? 1) >= level;
    useEffect(() => {
        if (isBought && level === 30) {
            setShowLevelDetails(false);
        }
    }, [levelData]);
    const handleLevelClick = () => {
        if (isUnlocked && !isBought) {
            handleLevel({
                preview_img,
                level,
                description: description + ' ' + t('common.computers') + ' | ' + preview_description_clicks + ' ' + t('common.per-click'),
                amount
            });
            setShowLevelDetails(true);
        }
    };
    const formattedAmount = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const displayAmount = formattedAmount?.endsWith('.00') ? formattedAmount?.slice(0, -3) : formattedAmount;
    return (_jsxs("div", { className: `${s.boostsLevel} ${isBought ? s.boostsLevelBuyed : ''}`, onClick: handleLevelClick, style: { display: isBought ? 'none' : '' }, children: [_jsx("img", { src: preview_img, alt: `level ${level}` }), _jsxs("div", { className: s.boostsLevelBody, children: [_jsxs("div", { className: s.boostsLevelTop, children: [`${t('common.level')} ${level}`, _jsx("div", { className: s.boostsLevelAction, children: isBought ? (_jsxs(_Fragment, { children: [t('common.you-have'), " ", _jsx(SvgSelector, { id: "isChecked" })] })) : (_jsx(_Fragment, { children: isUnlocked ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.boostsLevelPrice, children: [_jsx(SvgSelector, { id: "coin-small" }), " ", formatNumber(amount)] }), _jsx(SvgSelector, { id: "chevron-right" })] })) : (_jsxs(_Fragment, { children: [t('common.locked'), _jsx(SvgSelector, { id: "lock" })] })) })) })] }), _jsxs("div", { className: s.boostsLevelDescription, children: [description, " ", t('common.computers')] })] })] }));
};
