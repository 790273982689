import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v1 } from 'uuid';
import WebAppSDK from '@twa-dev/sdk';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { getUserData, userState } from '../../features/user/model';
import { prevLocation, updatePrevLocation } from '../../features/prev-location/model';
import { SquadsApiService } from '../../entities/squads';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import { Alert, Button, SkeletonInfo } from '../../shared/ui/components';
import { formatNumber } from '../../shared/utils/formatNumber';
import s from './squads-detail.module.scss';
export const SquadsDetail = () => {
    const { user, isLoading } = useUnit(userState);
    const prevLoc = useUnit(prevLocation);
    const { id } = useParams();
    const navigate = useNavigate();
    if (!id) {
        navigate('/');
    }
    const [data, setData] = useState(undefined);
    const [loadingSquad, setLoadingSquad] = useState(true);
    const [isCopiedLink, setIsCopiedLink] = useState(false);
    const [isJoiningSquad, setIsJoiningSquad] = useState(false);
    const [isLeavingSquad, setIsLeavingSquad] = useState(false);
    const { t } = useTranslation();
    const squadsApi = new SquadsApiService();
    const handleBack = () => {
        WebAppSDK.BackButton.hide();
        updatePrevLocation('/');
        navigate(prevLoc.length > 1 ? prevLoc : '/');
    };
    useEffect(() => {
        WebAppSDK.BackButton.show();
        WebAppSDK.BackButton.onClick(handleBack);
        window.scrollTo(0, 0);
        return () => WebAppSDK.BackButton.offClick(handleBack);
    }, []);
    const fetchData = async () => {
        try {
            setLoadingSquad(true);
            const result = await squadsApi.getSquadInfo(Number(id));
            getUserData({ shouldSetLoading: false });
            setData(result);
        }
        catch (error) {
            console.error('Error fetching squad info:', error);
            navigate('/');
        }
        finally {
            setLoadingSquad(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);
    const joinSquad = async () => {
        if (!data?.guild?.username) {
            return;
        }
        try {
            setIsJoiningSquad(true);
            const isSuccess = await squadsApi.joinSquad(data.guild.username);
            if (isSuccess) {
                WebAppSDK.showAlert(`${t('squads-detail.alert-conc')} ${data.guild.title}`);
                getUserData({ shouldSetLoading: false });
                fetchData();
            }
            else {
                WebAppSDK.showAlert(t('squads-detail.oops'));
            }
        }
        catch (error) {
            console.error('Error joining squad:', error);
            WebAppSDK.showAlert(t('squads-detail.oops'));
        }
        finally {
            setIsJoiningSquad(false);
        }
    };
    const leaveSquad = async () => {
        try {
            setIsLeavingSquad(true);
            const isSuccess = await squadsApi.leaveSquad();
            if (isSuccess) {
                WebAppSDK.showAlert(`${t('squads-detail.alert-leave')} ${data?.guild?.title ?? t('squads-detail.group')}`);
                getUserData({ shouldSetLoading: false });
            }
            else {
                WebAppSDK.showAlert(t('squads-detail.oops'));
            }
        }
        catch (error) {
            console.error('Error leaving squad:', error);
            WebAppSDK.showAlert(t('squads-detail.oops'));
        }
        finally {
            setIsLeavingSquad(false);
        }
    };
    const handleExit = () => {
        WebAppSDK.showConfirm(t('squads-detail.are-you-sure'), (isConfirmed) => {
            if (isConfirmed) {
                leaveSquad();
            }
        });
    };
    return (_jsxs("div", { className: s.detail, children: [data?.guild?.username && (_jsx("a", { href: `https://t.me/${data?.guild?.username}`, className: s.link, children: _jsx(SvgSelector, { id: "share2" }) })), isCopiedLink && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedLink(false), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", className: s.alert, children: t('squads-detail.invite-alert') })), _jsx("div", { className: s.detailLogo, style: { position: 'relative' }, children: !loadingSquad && (_jsxs(_Fragment, { children: [_jsx("img", { src: data?.guild?.avatarUrl, onLoad: (e) => {
                                const target = e.currentTarget;
                                if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                                    target.style.display = 'none';
                                }
                            }, alt: "", style: { zIndex: '5' } }), _jsx("div", { style: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, children: data?.guild.title?.slice(0, 2)?.toUpperCase() })] })) }), _jsx("h1", { className: s.detailTitle, children: loadingSquad ? (_jsx(SkeletonInfo, { height: "35", widthFull: true, backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" })) : (_jsx(_Fragment, { children: data?.guild.title })) }), _jsxs("div", { className: s.detailStats, children: [_jsxs("div", { className: s.detailStatsTop, children: [_jsxs("div", { className: s.detailStatsLeft, children: [_jsxs("div", { className: s.detailStatsData, children: [_jsx(SvgSelector, { id: "coin-small" }), loadingSquad ? (_jsx(SkeletonInfo, { height: "20", backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" })) : (formatNumber(data?.guild?.balance ?? 0))] }), t('squads-detail.coins-mined')] }), _jsx("div", { className: s.detailStatsDivider }), _jsxs("div", { className: s.detailStatsRight, children: [_jsx("div", { className: s.detailStatsData, children: loadingSquad ? (_jsx(SkeletonInfo, { height: "20", backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" })) : (formatNumber(data?.guild?.users_count ?? 0)) }), t('squads-detail.hackers')] })] }), loadingSquad ? (_jsxs(_Fragment, { children: [_jsx(Button, { color: "gray", disabled: true, children: t('squads-detail.loading') }), _jsx(Button, { color: "gray", disabled: true, children: t('squads-detail.loading') })] })) : user?.guildId !== data?.guild.id ? (_jsx(Button, { color: "green", onClick: joinSquad, disabled: isLoading || isJoiningSquad, children: t('buttons.join') })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: "green", onClick: () => {
                                    setIsCopiedLink(true);
                                    copy(`https://t.me/mnemonic_game_bot/start?startapp=guild_${data?.guild.id}`);
                                }, disabled: isLoading, children: t('squads-detail.invite-friend') }), _jsx(Button, { color: "gray", onClick: handleExit, disabled: isLoading || isLeavingSquad, children: t('squads-detail.leave-group') })] }))] }), _jsxs("div", { className: s.detailUsers, children: [_jsx("h2", { className: s.detailTitle2, children: t('squads-detail.best-hackers') }), _jsx("div", { className: s.topList, children: loadingSquad ? (new Array(10).fill(null).map((_, i) => (_jsx("div", { className: s.topItem, children: _jsxs("div", { className: s.topItemLeft, children: [_jsx("div", { className: s.topItemRating, children: i + 1 === 1 ? (_jsx("span", { children: "\uD83E\uDD47" })) : i + 1 === 2 ? (_jsx("span", { children: "\uD83E\uDD48" })) : i + 1 === 3 ? (_jsx("span", { children: "\uD83E\uDD49" })) : (i + 1) }), _jsx("div", { className: s.topItemAvatar }), _jsxs("div", { className: s.topItemInfo, children: [_jsx("div", { className: s.topItemName, children: _jsx(SkeletonInfo, { height: "15", backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" }) }), _jsxs("div", { className: s.topItemBalance, children: [_jsx(SvgSelector, { id: "coin-small" }), ' ', _jsx(SkeletonInfo, { height: "15", backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" })] })] })] }) }, v1())))) : data?.rating && data?.rating?.length ? (data?.rating?.map((el, i) => (_jsx("div", { className: s.topItem, children: _jsxs("div", { className: s.topItemLeft, children: [_jsx("div", { className: s.topItemRating, children: i + 1 === 1 ? (_jsx("span", { children: "\uD83E\uDD47" })) : i + 1 === 2 ? (_jsx("span", { children: "\uD83E\uDD48" })) : i + 1 === 3 ? (_jsx("span", { children: "\uD83E\uDD49" })) : (i + 1) }), _jsxs("div", { className: s.topItemAvatar, style: { position: 'relative' }, children: [_jsx("img", { src: el?.avatarUrl, onLoad: (e) => {
                                                    const target = e.currentTarget;
                                                    if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                                                        target.style.display = 'none';
                                                        el.username?.slice(0, 2)?.toUpperCase();
                                                    }
                                                }, style: { zIndex: '5' } }), _jsx("div", { style: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, children: el.username?.slice(0, 2)?.toUpperCase() || 'EL' })] }), _jsxs("div", { className: s.topItemInfo, children: [_jsx("div", { className: s.topItemName, children: el?.username ?? `user ${i + 1}` }), _jsxs("div", { className: s.topItemBalance, children: [_jsx(SvgSelector, { id: "coin-small" }), " ", formatNumber(el.balance)] })] })] }) }, v1())))) : (_jsx("div", { className: s.oops, children: t('squads-detail.group-empty') })) })] })] }));
};
