import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { v1 } from 'uuid';
import { TaskApiService } from '../../entities/task';
import { mockTasks } from '../../shared/mock/tasks';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import { TelegramStorage } from '../../shared/utils/telegram-storage';
import { getUserData } from '../../features/user/model';
import Question from '../../shared/lib/images/tasks/question.png';
import s from './task.module.scss';
export const Task = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();
    if (!id) {
        navigate('/');
        return _jsx(_Fragment, {});
    }
    const handleBack = () => {
        WebAppSDK.BackButton.hide();
        navigate('/boosts');
    };
    const storageTelegram = new TelegramStorage();
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const completedTasksFromStorage = JSON.parse(await storageTelegram.get('completedTasks') || '{}');
                const updatedMockTasks = mockTasks.map(task => ({
                    ...task,
                    tasks: task.tasks.map(step => ({
                        ...step,
                        completed: completedTasksFromStorage[`${task.id}_${step.id}`] || false
                    }))
                }));
                setTasks(updatedMockTasks);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);
    const taskApi = new TaskApiService();
    const onStepClick = async (taskId, stepId, itemGroup) => {
        WebAppSDK.openLink(mockTasks[taskId - 1].tasks[stepId - 1].link, { try_instant_view: true });
        if (itemGroup !== undefined) {
            const res = await taskApi.checkTask(window.Telegram.WebApp.initDataUnsafe.user.id, itemGroup);
            if (res === false) {
                return;
            }
        }
        const updatedTasks = [...tasks];
        updatedTasks[taskId - 1].tasks[stepId - 1].completed = true;
        setTasks(updatedTasks);
        const completedTasksFromStorage = JSON.parse(await storageTelegram.get('completedTasks') || '{}');
        completedTasksFromStorage[`${taskId}_${stepId}`] = true;
        storageTelegram.save('completedTasks', JSON.stringify(completedTasksFromStorage));
        const allSubtasksCompleted = updatedTasks[taskId - 1].tasks.every(subtask => subtask.completed);
        if (allSubtasksCompleted) {
            await taskApi.postTaskReward(Number(id));
            WebAppSDK.showAlert(t('common.task-completed'));
            getUserData({ shouldSetLoading: false });
        }
    };
    useEffect(() => {
        WebAppSDK.BackButton.show();
        WebAppSDK.BackButton.onClick(handleBack);
        window.scrollTo(0, 0);
        return () => WebAppSDK.BackButton.offClick(handleBack);
    }, []);
    return (_jsxs("div", { className: s.task, children: [_jsxs("div", { className: s.top, children: [_jsx("img", { src: Question, alt: "question", className: s.question }), _jsx("h1", { className: s.title, children: t('earn.title-task') }), _jsx("p", { className: s.description, children: t('earn.title-description') })] }), _jsxs("div", { className: s.steps, children: [_jsx("div", { className: s.title, children: t('earn.title-steps') }), _jsx("div", { className: s.stepsWrapper, children: tasks
                            .find(task => task?.id === Number(id) ?? 1)
                            ?.tasks.map((item, index) => (_jsxs("div", { className: `${s.row} ${item.completed ? s.completed : ''}`, onClick: () => !item.completed && onStepClick(Number(id), item.id, item?.group_id), children: [_jsxs("div", { className: s.rowContent, children: [_jsx("div", { className: s.index, children: index + 1 }), _jsxs("div", { className: s.body, children: [_jsx("div", { className: s.img, children: _jsx("img", { src: item.preview, alt: "preview" }) }), _jsxs("div", { className: s.content, children: [_jsx("div", { className: s.name, children: t(item.title) }), item?.description.length > 1 && (_jsx("div", { className: s.description, children: t(item.description) }))] })] })] }), item.completed ? (_jsx("div", { className: s.icon, children: _jsx(SvgSelector, { id: "checked" }) })) : (_jsx("div", { className: s.icon, children: _jsx(SvgSelector, { id: "chevron-right" }) }))] }, v1()))) })] })] }));
};
