import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { userState } from '../../../../features/user/model';
import { LevelsData } from '../../../../shared/mock/levels';
import { Level } from '../level';
import s from './levels-list.module.scss';
export const LevelsList = () => {
    const { user } = useUnit(userState);
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.boostsTitle, children: t('boosts.hackers-title') }), _jsx("div", { className: s.boostsLevels, children: LevelsData?.slice(0, (user?.computerId ?? 1) + 1)?.map(levelData => (_jsx(Level, { levelData: levelData, user: user }, levelData.level))) })] }));
};
