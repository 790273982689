// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CpAi5b38kUFdGJ3dvZrQ{height:100vh;position:relative;overflow:auto !important}.oznVDic42XB2mGROF69j{position:absolute;bottom:0;left:50%;transform:translateX(-50%);object-fit:cover;width:100%;height:75%;z-index:-1}.nCgdAe2MiFDizBz1qkFA{height:100% !important}.aW5mMNT1Mtdub0WDdDXp{max-width:425px;margin:0 auto;padding:28px 15px 0 15px;overflow:auto;border-radius:5px}.XFlkFHfbDwtArBjwAtRh{position:fixed;left:50%;bottom:.9375rem;transform:translateX(-50%);z-index:10;max-width:358px;width:100%;padding:0 5px}`, "",{"version":3,"sources":["webpack://./src/shared/ui/layouts/main-layout/main-layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,wBAAA,CACA,sBACE,iBAAA,CACA,QAAA,CACA,QAAA,CACA,0BAAA,CACA,gBAAA,CACA,UAAA,CACA,UAAA,CACA,UAAA,CAEF,sBACE,sBAAA,CAEF,sBACE,eAAA,CACA,aAAA,CACA,wBAAA,CACA,aAAA,CACA,iBAAA,CAIJ,sBACE,cAAA,CACA,QAAA,CACA,eAAA,CACA,0BAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  height: 100vh;\n  position: relative;\n  overflow: auto !important;\n  &Bg {\n    position: absolute;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    object-fit: cover;\n    width: 100%;\n    height: 75%;\n    z-index: -1;\n  }\n  &Bg2 {\n    height: 100% !important;\n  }\n  &Content {\n    max-width: 425px;\n    margin: 0 auto;\n    padding: 28px 15px 0 15px;\n    overflow: auto;\n    border-radius: 5px;\n  }\n}\n\n.actions {\n  position: fixed;\n  left: 50%;\n  bottom: 0.9375rem;\n  transform: translateX(-50%);\n  z-index: 10;\n  max-width: 358px;\n  width: 100%;\n  padding: 0 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CpAi5b38kUFdGJ3dvZrQ`,
	"wrapperBg": `oznVDic42XB2mGROF69j`,
	"wrapperBg2": `nCgdAe2MiFDizBz1qkFA`,
	"wrapperContent": `aW5mMNT1Mtdub0WDdDXp`,
	"actions": `XFlkFHfbDwtArBjwAtRh`
};
export default ___CSS_LOADER_EXPORT___;
