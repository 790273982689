import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { Modal } from '../../../../shared/ui/components';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import { handleSkin, setShowSkin, showSkin, skinDetails } from '../../../../features/skin/model';
import BG from '../../../../shared/lib/images/background.png';
import s from './skin-detail.module.scss';
export const SkinDetail = ({ user, onClick }) => {
    const skinDetailData = useUnit(skinDetails);
    const showSkinDetail = useUnit(showSkin);
    const TgObj = WebAppSDK;
    const { t } = useTranslation();
    if (!skinDetailData)
        return _jsx(_Fragment, {});
    const handleClick = () => {
        if (user && user?.skinId) {
            if (user?.skinId === skinDetailData?.skinId)
                return;
            onClick((user?.skinId ?? 0) + 1);
        }
    };
    const handleCloseModal = () => {
        setShowSkin(false);
        TgObj.MainButton.hide();
        window.document.body.style.overflow = 'visible';
        handleSkin(null);
    };
    useEffect(() => {
        TgObj.MainButton.text = t('buttons.get');
        TgObj.MainButton.show();
        if (!user) {
            TgObj.MainButton.disable();
            TgObj.MainButton.color = '#666';
            return;
        }
        if (user?.balance >= (skinDetailData?.amount ?? 0)) {
            TgObj.MainButton.enable();
            TgObj.MainButton.color = '#007AFF';
            TgObj.MainButton.onClick(handleClick);
        }
        else {
            TgObj.MainButton.disable();
            TgObj.MainButton.color = '#666';
        }
        return () => TgObj.MainButton.offClick(handleClick);
    }, [skinDetailData, user]);
    const formattedAmount = skinDetailData?.amount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const displayAmount = formattedAmount?.endsWith('.00') ? formattedAmount?.slice(0, -3) : formattedAmount;
    return (_jsx("div", { className: s.wrapper, children: _jsxs(Modal, { isOpen: showSkinDetail, onClose: handleCloseModal, isCloseButton: false, className: s.modal, children: [_jsx("img", { src: BG, className: s.modalBg, alt: "bg" }), _jsx("div", { className: s.modalTop, children: _jsx("button", { className: s.modalTopButton, onClick: handleCloseModal, children: _jsx(SvgSelector, { id: "close" }) }) }), _jsx("img", { className: s.modalPreview, src: skinDetailData?.preview_img, alt: "preview" }), _jsx("h2", { className: s.modalTitle, children: t(skinDetailData?.description ?? '') }), _jsxs("div", { className: s.modalAmount, children: [_jsx(SvgSelector, { id: "coin-small" }), displayAmount] })] }) }));
};
