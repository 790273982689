import Level1 from '../lib/images/levels/level1.png';
import Level2 from '../lib/images/levels/level2.png';
import Level3 from '../lib/images/levels/level3.png';
import Level4 from '../lib/images/levels/level4.png';
import Level5 from '../lib/images/levels/level5.png';
import Level6 from '../lib/images/levels/level6.png';
import Level7 from '../lib/images/levels/level7.png';
import Level8 from '../lib/images/levels/level8.png';
import Level9 from '../lib/images/levels/level9.png';
import Level10 from '../lib/images/levels/level10.png';
import Level11 from '../lib/images/levels/level11.png';
import Level12 from '../lib/images/levels/level12.png';
import Level13 from '../lib/images/levels/level13.png';
import Level14 from '../lib/images/levels/level14.png';
import Level15 from '../lib/images/levels/level15.png';
import Level16 from '../lib/images/levels/level16.png';
import Level17 from '../lib/images/levels/level17.png';
import Level18 from '../lib/images/levels/level18.png';
import Level19 from '../lib/images/levels/level19.png';
import Level20 from '../lib/images/levels/level20.png';
import Level21 from '../lib/images/levels/level21.png';
import Level22 from '../lib/images/levels/level22.png';
import Level23 from '../lib/images/levels/level23.png';
import Level24 from '../lib/images/levels/level24.png';
import Level25 from '../lib/images/levels/level25.png';
import Level26 from '../lib/images/levels/level26.png';
import Level27 from '../lib/images/levels/level27.png';
import Level28 from '../lib/images/levels/level28.png';
import Level29 from '../lib/images/levels/level29.png';
import Level30 from '../lib/images/levels/level30.png';
import Levell2 from '../lib/images/steps/level2.png';
import Levell3 from '../lib/images/steps/level3.png';
import Levell4 from '../lib/images/steps/level4.png';
import Levell5 from '../lib/images/steps/level5.png';
import Levell6 from '../lib/images/steps/level6.png';
import Levell7 from '../lib/images/steps/level7.png';
import Levell8 from '../lib/images/steps/level8.png';
import Levell9 from '../lib/images/steps/level9.png';
import Levell10 from '../lib/images/steps/level10.png';
import Levell11 from '../lib/images/steps/level11.png';
import Levell12 from '../lib/images/steps/level12.png';
import Levell13 from '../lib/images/steps/level13.png';
import Levell14 from '../lib/images/steps/level14.png';
import Levell15 from '../lib/images/steps/level15.png';
import Levell16 from '../lib/images/steps/level16.png';
import Levell17 from '../lib/images/steps/level17.png';
import Levell18 from '../lib/images/steps/level18.png';
import Levell19 from '../lib/images/steps/level19.png';
import Levell20 from '../lib/images/steps/level20.png';
import Levell21 from '../lib/images/steps/level21.png';
import Levell22 from '../lib/images/steps/level22.png';
import Levell23 from '../lib/images/steps/level23.png';
import Levell24 from '../lib/images/steps/level24.png';
import Levell25 from '../lib/images/steps/level25.png';
import Levell26 from '../lib/images/steps/level26.png';
import Levell27 from '../lib/images/steps/level27.png';
import Levell28 from '../lib/images/steps/level28.png';
import Levell29 from '../lib/images/steps/level29.png';
import Levell30 from '../lib/images/steps/level30.png';
export const LevelsData = [
    {
        level: 1,
        amount: 0,
        description: 'Бесплатно',
        preview_img: Level1,
        preview_detail_img: Level1,
        preview_description_clicks: '+29'
    },
    {
        level: 2,
        amount: 3000,
        description: '200',
        preview_img: Level2,
        preview_detail_img: Levell2,
        preview_description_clicks: '+2'
    },
    {
        level: 3,
        amount: 6000,
        description: '300',
        preview_img: Level3,
        preview_detail_img: Levell3,
        preview_description_clicks: '+3'
    },
    {
        level: 4,
        amount: 12000,
        description: '400',
        preview_img: Level4,
        preview_detail_img: Levell4,
        preview_description_clicks: '+4'
    },
    {
        level: 5,
        amount: 24000,
        description: '500',
        preview_img: Level5,
        preview_detail_img: Levell5,
        preview_description_clicks: '+5'
    },
    {
        level: 6,
        amount: 48000,
        description: '600',
        preview_img: Level6,
        preview_detail_img: Levell6,
        preview_description_clicks: '+6'
    },
    {
        level: 7,
        amount: 96000,
        description: '700',
        preview_img: Level7,
        preview_detail_img: Levell7,
        preview_description_clicks: '+7'
    },
    {
        level: 8,
        amount: 192000,
        description: '800',
        preview_img: Level8,
        preview_detail_img: Levell8,
        preview_description_clicks: '+8'
    },
    {
        level: 9,
        amount: 384000,
        description: '900',
        preview_img: Level9,
        preview_detail_img: Levell9,
        preview_description_clicks: '+9'
    },
    {
        level: 10,
        amount: 768000,
        description: '1000',
        preview_img: Level10,
        preview_detail_img: Levell10,
        preview_description_clicks: '+10'
    },
    {
        level: 11,
        amount: 1536000,
        description: '1100',
        preview_img: Level11,
        preview_detail_img: Levell11,
        preview_description_clicks: '+11'
    },
    {
        level: 12,
        amount: 3072000,
        description: '1200',
        preview_img: Level12,
        preview_detail_img: Levell12,
        preview_description_clicks: '+12'
    },
    {
        level: 13,
        amount: 6144000,
        description: '1300',
        preview_img: Level13,
        preview_detail_img: Levell13,
        preview_description_clicks: '+13'
    },
    {
        level: 14,
        amount: 12288000,
        description: '1400',
        preview_img: Level14,
        preview_detail_img: Levell14,
        preview_description_clicks: '+14'
    },
    {
        level: 15,
        amount: 24576000,
        description: '1500',
        preview_img: Level15,
        preview_detail_img: Levell15,
        preview_description_clicks: '+15'
    },
    {
        level: 16,
        amount: 49152000,
        description: '1600',
        preview_img: Level16,
        preview_detail_img: Levell16,
        preview_description_clicks: '+16'
    },
    {
        level: 17,
        amount: 98304000,
        description: '1700',
        preview_img: Level17,
        preview_detail_img: Levell17,
        preview_description_clicks: '+17'
    },
    {
        level: 18,
        amount: 196608000,
        description: '1800',
        preview_img: Level18,
        preview_detail_img: Levell18,
        preview_description_clicks: '+18'
    },
    {
        level: 19,
        amount: 393216000,
        description: '1900',
        preview_img: Level19,
        preview_detail_img: Levell19,
        preview_description_clicks: '+19'
    },
    {
        level: 20,
        amount: 786432000,
        description: '2000',
        preview_img: Level20,
        preview_detail_img: Levell20,
        preview_description_clicks: '+20'
    },
    {
        level: 21,
        amount: 1572864000,
        description: '2100',
        preview_img: Level21,
        preview_detail_img: Levell21,
        preview_description_clicks: '+21'
    },
    {
        level: 22,
        amount: 3145728000,
        description: '2200',
        preview_img: Level22,
        preview_detail_img: Levell22,
        preview_description_clicks: '+22'
    },
    {
        level: 23,
        amount: 6291456000,
        description: '2300',
        preview_img: Level23,
        preview_detail_img: Levell23,
        preview_description_clicks: '+23'
    },
    {
        level: 24,
        amount: 12582912000,
        description: '2400',
        preview_img: Level24,
        preview_detail_img: Levell24,
        preview_description_clicks: '+24'
    },
    {
        level: 25,
        amount: 25165824000,
        description: '2500',
        preview_img: Level25,
        preview_detail_img: Levell25,
        preview_description_clicks: '+25'
    },
    {
        level: 26,
        amount: 50331648000,
        description: '2600',
        preview_img: Level26,
        preview_detail_img: Levell26,
        preview_description_clicks: '+26'
    },
    {
        level: 27,
        amount: 100663296000,
        description: '2700',
        preview_img: Level27,
        preview_detail_img: Levell27,
        preview_description_clicks: '+27'
    },
    {
        level: 28,
        amount: 201326592000,
        description: '2800',
        preview_img: Level28,
        preview_detail_img: Levell28,
        preview_description_clicks: '+28'
    },
    {
        level: 29,
        amount: 402653184000,
        description: '2900',
        preview_img: Level29,
        preview_detail_img: Levell29,
        preview_description_clicks: '+29'
    },
    {
        level: 30,
        amount: 805306368000,
        description: '3000',
        preview_img: Level30,
        preview_detail_img: Levell30,
        preview_description_clicks: '+30'
    }
];
