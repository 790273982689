import WebApp from '@twa-dev/sdk';
import { io } from 'socket.io-client';
// "undefined" means the URL will be computed from the `window.location` object
// const URL = 'https://game-socket-msq9q.ondigitalocean.app/';
const URL = 'https://game-socket-msq9q.ondigitalocean.app/';
export const socket = io(URL, {
    query: { tgData: WebApp.initData.toString() },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 5,
    transports: ['websocket']
});
