import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { handleSkin, setShowSkin } from '../../../../features/skin/model';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import s from './skin.module.scss';
import { formatNumber } from '../../../../shared/utils/formatNumber';
export const Skin = ({ user, skinData }) => {
    const { amount, description, level, preview_img } = skinData;
    const isUnlocked = user?.skinId && user?.skinId >= level;
    const isBought = (user?.skinId ?? 1) >= level;
    const { t } = useTranslation();
    const handleSkinClick = () => {
        if (isUnlocked) {
            handleSkin({
                preview_img,
                level: 0,
                description: skinData?.description,
                amount
            });
            setShowSkin(true);
        }
    };
    const formattedAmount = amount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    const displayAmount = formattedAmount?.endsWith('.00')
        ? formattedAmount?.slice(0, -3)
        : formattedAmount;
    return (_jsxs("div", { className: `${s.boostsLevel}`, onClick: handleSkinClick, children: [_jsx("img", { src: preview_img, alt: `level ${level}` }), _jsxs("div", { className: s.boostsLevelBody, children: [_jsxs("div", { className: s.boostsLevelTop, children: [t(description), _jsxs("div", { className: s.boostsLevelAction, children: [_jsxs("div", { className: s.boostsLevelPrice, children: [_jsx(SvgSelector, { id: "coin-small" }), " ", formatNumber(amount)] }), _jsx(SvgSelector, { id: "chevron-right" })] })] }), _jsx("div", { className: s.boostsLevelDescription, children: isUnlocked ? (_jsx(_Fragment, { children: !isBought && 'You can buy' })) : (_jsxs(_Fragment, { children: [t('common.locked'), _jsx(SvgSelector, { id: "lock" })] })) })] })] }));
};
