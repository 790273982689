import { createEvent, createStore, combine } from 'effector';
import { FriendsApiService } from '../../entities/friends/api/friendsApi';
export const setFriends = createEvent();
export const setIsLoading = createEvent();
export const getFriendsData = createEvent();
export const friendsStore = createStore([])
    .on(setFriends, (_, payload) => payload);
export const isLoadingStore = createStore(false)
    .on(setIsLoading, (_, payload) => payload);
const friendsApiService = new FriendsApiService();
getFriendsData.watch(async () => {
    try {
        setIsLoading(true);
        const res = await friendsApiService.getUserFriends();
        setFriends(res);
    }
    catch (err) {
        console.error('Error fetching friends:', err);
        setFriends([]);
    }
    finally {
        setIsLoading(false);
    }
});
export const friendsState = combine({ friends: friendsStore, friendsLoading: isLoadingStore });
