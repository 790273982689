import COIN from '../lib/images/tasks/coin.jpg';
import ARBUZ from '../lib/images/tasks/arbuz.jpg';
import DELAB from '../lib/images/tasks/delab.jpg';
import DELAB_LIVE from '../lib/images/tasks/delab-live.jpg';
import META_SILENCE from '../lib/images/tasks/meta-silence.jpg';
import DEFINDER from '../lib/images/tasks/de-finder.jpg';
import META from '../lib/images/tasks/meta.jpg';
import KINGY from '../lib/images/tasks/kingy.jpg';
export const mockTasks = [
    {
        id: 1,
        title: 'earn-tasks.task1.title',
        description: 'earn-tasks.task1.description',
        reward: 200000,
        preview: COIN,
        tasks: [
            {
                id: 1,
                title: 'earn-tasks.task1.tasks.task1.title',
                description: 'earn-tasks.task1.tasks.task1.description',
                group_id: '-1002078777987',
                link: 'https://t.me/secretoncoin',
                completed: false,
                reward: 200000,
                preview: COIN
            },
            {
                id: 2,
                title: 'earn-tasks.task1.tasks.task2.title',
                description: 'earn-tasks.task1.tasks.task2.description',
                link: 'https://t.me/secretcoinchat',
                group_id: '@secretcoinchat',
                completed: false,
                reward: 200000,
                preview: COIN
            }
            // {
            //     id: 3,
            //     title: 'earn-tasks.task1.tasks.task3.title',
            //     description: 'earn-tasks.task1.tasks.task3.description',
            //     link: 'https://t.me/mnemonic_game_bot',
            //     group_id: '@mnemonic_game_bot',
            //     completed: false,
            //     reward: 200000,
            //     preview: COIN
            // }
        ]
    },
    {
        id: 2,
        title: 'earn-tasks.task2.title',
        description: 'earn-',
        reward: 250000,
        preview: ARBUZ,
        tasks: [
            {
                id: 1,
                title: 'earn-tasks.task2.tasks.task1.title',
                description: '',
                group_id: '@tonarbuz',
                link: 'https://t.me/chat_tonarbuz',
                completed: false,
                reward: 250000,
                preview: ARBUZ
            }
        ]
    },
    {
        id: 3,
        title: 'earn-tasks.task3.title',
        description: 'earn-',
        reward: 250000,
        preview: DELAB,
        tasks: [
            {
                id: 1,
                title: 'earn-tasks.task3.tasks.task1.title',
                description: '',
                group_id: '@uninft',
                link: 'https://t.me/uninft',
                completed: false,
                reward: 250000,
                preview: DELAB_LIVE
            },
            {
                id: 2,
                title: 'earn-tasks.task3.tasks.task2.title',
                description: '',
                group_id: '@delab',
                link: 'https://t.me/delab',
                completed: false,
                reward: 250000,
                preview: DELAB
            }
        ]
    },
    {
        id: 4,
        title: 'earn-tasks.task4.title',
        description: 'earn-',
        reward: 500000,
        preview: META_SILENCE,
        tasks: [
            {
                id: 1,
                title: 'earn-tasks.task4.tasks.task1.title',
                description: '',
                group_id: '-1001771147489',
                link: 'https://t.me/+-EOfWx2pRKhmNGE6',
                completed: false,
                reward: 500000,
                preview: META_SILENCE
            },
            {
                id: 2,
                title: 'earn-tasks.task4.tasks.task2.title',
                description: '',
                group_id: '-1001556583589',
                link: 'https://t.me/de_findercapital',
                completed: false,
                reward: 500000,
                preview: DEFINDER
            },
            {
                id: 3,
                title: 'earn-tasks.task4.tasks.task3.title',
                description: '',
                group_id: '-1001933556419',
                link: 'https://t.me/chatmetasiliense',
                completed: false,
                reward: 500000,
                preview: META
            }
        ]
    },
    {
        id: 5,
        title: 'earn-tasks.task5.title',
        description: 'earn-',
        reward: 350000,
        preview: KINGY,
        tasks: [
            {
                id: 1,
                title: 'earn-tasks.task5.tasks.task1.title',
                description: '',
                group_id: '-1001412873120',
                link: 'https://t.me/investkingyru',
                completed: false,
                reward: 350000,
                preview: KINGY
            }
        ]
    }
];
