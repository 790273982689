/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unneeded-ternary */
import { combine, createEvent, createStore } from 'effector';
import { HistoryApiService } from '../../entities/history';
export const setShowHistory = createEvent();
export const showHistory = createStore(false).on(setShowHistory, (_, payload) => payload);
export const setHistory = createEvent();
export const historyStore = createStore(null)
    .on(setHistory, (_, newData) => newData);
export const isLoadingStore = createStore(false);
export const setIsLoading = createEvent();
isLoadingStore.on(setIsLoading, (_, isLoading) => isLoading);
export const getHistoryCases = createEvent();
const historyApi = new HistoryApiService();
getHistoryCases.watch(async () => {
    try {
        setIsLoading(true);
        const res = await historyApi.getHistoryCases();
        setHistory(res);
    }
    catch (err) {
        console.error(err);
    }
    finally {
        setIsLoading(false);
    }
});
export const historyState = combine({ isLoading: isLoadingStore, historyData: historyStore });
