import ARBUZ from '../lib/images/skins/preview/cyber-arbuz.png';
import VR from '../lib/images/skins/preview/vr.png';
import LOPATA from '../lib/images/skins/preview/lopata.png';
import Preview1 from '../lib/images/skins/01.png';
import Preview2 from '../lib/images/skins/02.png';
import Preview3 from '../lib/images/skins/03.png';
export const SkinsData = [
    {
        level: 1,
        amount: 5000000,
        description: 'skins.gold-shovel',
        preview_img: LOPATA,
        img: Preview3
    },
    {
        level: 2,
        amount: 10000000,
        description: 'skins.vr-helmet',
        preview_img: VR,
        img: Preview2
    },
    {
        level: 3,
        amount: 15000000,
        description: 'skins.cyber-arbuz',
        preview_img: ARBUZ,
        img: Preview1
    }
];
