import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WebAppSDK from '@twa-dev/sdk';
import { getUserData, userState } from '../../features/user/model';
import { setShowLevelDetails, showLevelsDetails } from '../../features/level/model';
import { setShowSkin, showSkin } from '../../features/skin/model';
import { Balance } from '../../shared/ui/components/balance';
import { TasksList } from '../../widgets/tasks/ui/tasks-list';
import { LevelsList } from '../../widgets/levels/ui/levels-list';
import { SkinList } from '../../widgets/skins/ui/skin-list';
import { LevelDetail } from '../../widgets/levels/ui/level-detail';
import { SkinDetail } from '../../widgets/skins/ui/skin-detail';
import { SkinApiService } from '../../entities/skin';
import s from './boosts.module.scss';
export const Boosts = () => {
    const { user } = useUnit(userState);
    const showLevelDetailState = useUnit(showLevelsDetails);
    const showSkinDetailState = useUnit(showSkin);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const TgObj = WebAppSDK;
    useEffect(() => {
        if (showLevelDetailState && user) {
            window.document.body.style.overflow = 'hidden';
            TgObj.MainButton.show();
            TgObj.MainButton.enable();
            TgObj.MainButton.text = t('buttons.get');
        }
        else {
            TgObj.MainButton.hide();
        }
        return () => {
            window.document.body.style.overflow = 'visible';
        };
    }, [showLevelDetailState, user]);
    useEffect(() => {
        if (showSkinDetailState) {
            window.document.body.style.overflow = 'hidden';
        }
        return () => {
            window.document.body.style.overflow = 'visible';
        };
    }, [showSkinDetailState]);
    const skinApi = new SkinApiService();
    const handleBuyComputers = async (amount) => {
        let res;
        try {
            TgObj.MainButton.showProgress();
            TgObj.MainButton.disable();
            res = await skinApi.buyComputer(amount);
            if (res) {
                TgObj.MainButton.hide();
                setShowLevelDetails(false);
                TgObj.showAlert(`${t('boosts.alert-conc')} ${amount} `);
                getUserData({ shouldSetLoading: false });
            }
            else {
                TgObj.MainButton.show();
            }
        }
        catch (error) {
            console.error('Error buying computers:', error);
            TgObj.MainButton.show();
        }
        finally {
            TgObj.MainButton.enable();
            TgObj.MainButton.show();
            TgObj.MainButton.hideProgress();
        }
        return res;
    };
    const handleBuySkin = async (skinId) => {
        let res;
        try {
            TgObj.MainButton.showProgress();
            TgObj.MainButton.disable();
            res = await skinApi.buySkin(skinId);
            if (res) {
                TgObj.MainButton.hide();
                TgObj.showAlert(`${t('boosts.alert-conc2')} ${skinId} `);
                setShowSkin(false);
                getUserData({ shouldSetLoading: false });
            }
            else {
                TgObj.MainButton.show();
            }
        }
        catch (error) {
            console.error('Error buying skins:', error);
            TgObj.MainButton.show();
        }
        finally {
            TgObj.MainButton.enable();
            TgObj.MainButton.show();
            TgObj.MainButton.hideProgress();
        }
    };
    return (_jsxs("div", { className: s.boosts, children: [showLevelDetailState && (_jsx(LevelDetail, { user: user, onClick: handleBuyComputers })), showSkinDetailState && (_jsx(SkinDetail, { user: user, onClick: handleBuySkin })), _jsx("div", { className: s.boostsTop, children: _jsx(Balance, { prevLoc: "/boosts" }) }), _jsx(TasksList, {}), (user?.computerId ?? 1) !== 30 && (_jsx(LevelsList, {})), user?.skinId && user?.skinId !== 4 && (_jsx(SkinList, {}))] }));
};
