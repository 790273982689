import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { friendsState } from '../../../../features/friends/model';
import s from './friends-list.module.scss';
import { FriendItem } from '../friend-item/friend-item';
import { FriendItemSkeleton } from '../friend-item/friend-item-skeleton';
export const FriendsList = () => {
    const { friends, friendsLoading } = useUnit(friendsState);
    const { t } = useTranslation();
    return (_jsxs("div", { className: s.friends, children: [_jsxs("h2", { className: s.friendsTitle, children: [t('friends.friends-list'), " ", _jsx("span", { children: friends?.length ?? 0 })] }), _jsx("div", { className: s.friendsList, children: friendsLoading ? new Array(5).fill(null).map(_ => _jsx(FriendItemSkeleton, {}, v1())) : (friends?.length ? (friends?.sort((a, b) => {
                    const balanceA = a?.balance || 0;
                    const balanceB = b?.balance || 0;
                    return balanceB - balanceA;
                }).map(el => (_createElement(FriendItem, { ...el, key: v1() })))) : (_jsx("div", { className: s.friendsListEmpty, children: t('friends.empty-friend-list') }))) })] }));
};
