/* eslint-disable no-debugger */
/* eslint-disable import/no-extraneous-dependencies */
import { createEvent, createStore, combine } from 'effector';
import WebAppSDK from '@twa-dev/sdk';
import { UserApiService } from '../../entities/user';
import { setUserEnergyRemain } from '../click/model';
import { getCaseList } from '../case/model';
import { getHistoryCases } from '../history/model';
import { getJettonsData } from '../buy-jettons/model';
import { getFriendsData } from '../friends/model';
import { getTop } from '../top/model';
import { checkTasks } from '../taks/model';
export const setUser = createEvent();
export const userStore = createStore(null)
    .on(setUser, (_, newUser) => newUser);
export const isErrorStore = createStore(false);
export const setIsError = createEvent();
isErrorStore.on(setIsError, (_, isError) => isError);
export const isLoadingStore = createStore(false);
export const setIsLoading = createEvent();
isLoadingStore.on(setIsLoading, (_, isLoading) => isLoading);
export const getUserData = createEvent();
const userApiService = new UserApiService();
getUserData.watch(async ({ id, shouldSetLoading = true }) => {
    try {
        if (shouldSetLoading) {
            setIsLoading(true);
        }
        const res = await userApiService.getUser(id);
        if (!res) {
            setIsError(true);
        }
        else {
            setIsError(false);
            setUser(res);
            setUserEnergyRemain(res.energy);
            WebAppSDK.MainButton.hide();
        }
    }
    catch (error) {
        console.error('Error initializing user:', error);
        setIsError(true);
        WebAppSDK.MainButton.show();
    }
    finally {
        if (shouldSetLoading) {
            getCaseList();
            getHistoryCases();
            getJettonsData();
            getFriendsData();
            getTop();
            checkTasks();
            setIsLoading(false);
        }
    }
});
export const userState = combine({
    user: userStore,
    isError: isErrorStore,
    isLoading: isLoadingStore
});
