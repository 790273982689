/* eslint-disable no-debugger */
/* eslint-disable import/no-extraneous-dependencies */
import { createEvent, createStore, combine } from 'effector';
import { TelegramStorage } from '../../shared/utils/telegram-storage';
import { mockTasks } from '../../shared/mock/tasks';
import { TaskApiService } from '../../entities/task';
export const setTasks = createEvent();
export const tasksStore = createStore([])
    .on(setTasks, (_, newTask) => newTask);
export const setTasksLoading = createEvent();
export const tasksLoadingStore = createStore(false)
    .on(setTasksLoading, (_, newState) => newState);
const telegramStorage = new TelegramStorage();
const taskApi = new TaskApiService();
export const checkTasks = createEvent();
checkTasks.watch(async () => {
    try {
        setTasksLoading(true);
        const updatedTasks = await Promise.all(mockTasks.map(async (task) => {
            const updatedSubtasks = await Promise.all(task.tasks.map(async (subtask) => {
                const res = await taskApi.checkTask(window.Telegram.WebApp.initDataUnsafe.user.id, String(subtask.group_id));
                return {
                    ...subtask,
                    completed: res
                };
            }));
            return {
                ...task,
                tasks: updatedSubtasks
            };
        }));
        setTasks(updatedTasks);
        const completedTasksFromStorage = JSON.parse(await telegramStorage.get('completedTasks') || '{}');
        updatedTasks.forEach((task) => {
            task.tasks.forEach((subtask) => {
                completedTasksFromStorage[`${task.id}_${subtask.id}`] = subtask.completed;
            });
        });
        telegramStorage.save('completedTasks', JSON.stringify(completedTasksFromStorage));
        updatedTasks.forEach((task) => {
            const allSubtasksCompleted = task.tasks.every(subtask => subtask.completed);
            if (allSubtasksCompleted) {
                taskApi.postTaskReward(task.id);
            }
        });
        setTasksLoading(false);
    }
    catch (error) {
        console.error('Error while checking tasks:', error);
    }
});
export const taskState = combine({ tasks: tasksStore, tasksLoading: tasksLoadingStore });
