import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useUnit } from 'effector-react';
import WebAppSDK from '@twa-dev/sdk';
import { getLevelComponent } from './getLevelComponent';
import { Stats } from '../../shared/ui/components';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import { setUser, userState } from '../../features/user/model';
import { updatePrevLocation } from '../../features/prev-location/model';
import { clicksState, setAwardsClicks, setClicks, setUserEnergyRemain } from '../../features/click/model';
import ARBUZ from '../../shared/lib/images/skins/preview/cyber-arbuz.png';
import VR from '../../shared/lib/images/skins/preview/vr.png';
import LOPATA from '../../shared/lib/images/skins/preview/lopata.png';
import s from './home.module.scss';
import { socket } from '../../shared/utils/socket';
export const Home = () => {
    const navigate = useNavigate();
    const { user } = useUnit(userState);
    const { t } = useTranslation();
    const location = useLocation();
    //= =======================================================================================================================================================
    const { clicks, awardsClicks, userEnergyRemain } = useUnit(clicksState);
    //= =======================================================================================================================================================
    // const [ clicksCount, setClicksCount ] = useState<number>(0)
    const [clickPositions, setClickPositions] = useState([]);
    const [tapCount, setTapCount] = useState(0);
    const [tapTimeout, setTapTimeout] = useState(null);
    const sendClickToServer = () => {
        socket.emit('tap', { count: clicks });
        setClicks(0);
    };
    useEffect(() => {
        let send;
        if (clicks > 0 && userEnergyRemain >= (user?.computerId ?? 1)) {
            send = setTimeout(() => {
                sendClickToServer();
            }, 600);
        }
        const intervalId = setInterval(() => {
            sendClickToServer();
        }, 3000);
        return () => {
            if (send)
                clearTimeout(send);
            clearInterval(intervalId);
        };
    }, [clicks, userEnergyRemain, user?.computerId]);
    const handleClick = (event) => {
        if (user && user.balance !== undefined) {
            if (userEnergyRemain >= user?.computerId) {
                const buttonRect = event.currentTarget.getBoundingClientRect();
                const offsetX = event.clientX - buttonRect.left;
                const offsetY = event.clientY - buttonRect.top;
                setClickPositions(prevPositions => [
                    ...prevPositions,
                    { id: Date.now(), x: offsetX, y: offsetY }
                ]);
                if (tapTimeout) {
                    setTapCount(prevCount => prevCount + 1);
                }
                else {
                    const timeout = setTimeout(() => {
                        setTapTimeout(null);
                        setTapCount(0);
                    }, 500);
                    setTapTimeout(timeout);
                }
                setClicks(clicks + 1);
                const updatedBalance = user.balance + 1 * (user?.computerId ?? 1);
                setUser({ ...user, balance: updatedBalance });
                setAwardsClicks(awardsClicks + 5);
                WebAppSDK.HapticFeedback.impactOccurred('medium');
                localStorage.setItem('awardsClicks', String(awardsClicks + 5));
                setUserEnergyRemain(userEnergyRemain - (user?.computerId ?? 1));
            }
            else {
                console.warn('User energy is insufficient');
            }
        }
        else {
            console.error('Invalid user or balance');
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            if (location.pathname === '/') {
                document.body.style.overflow = 'hidden';
            }
        };
        handleScroll();
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [location.pathname]);
    const handleNavigateTop = () => {
        navigate('/top');
        updatePrevLocation('/');
    };
    useEffect(() => () => {
        sendClickToServer();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.inner, children: [_jsx(Stats, { awardsClicks: awardsClicks }), _jsxs("div", { className: s.rating, children: ["#", user?.rank, _jsxs("button", { className: s.ratingDetail, onClick: handleNavigateTop, children: [_jsx(SvgSelector, { id: "trophy" }), t('home.stats'), " ", _jsx(SvgSelector, { id: "chevron-right" })] })] })] }), _jsxs("div", { className: s.level, children: [_jsxs("button", { className: s.levelUp, onClick: handleClick, children: [user?.skinId === 1 ? (getLevelComponent(user?.computerId ?? 0)) : (_jsxs(_Fragment, { children: [user?.skinId === 2 && _jsx("img", { src: LOPATA, alt: "lopata" }), user?.skinId === 3 && _jsx("img", { src: VR, alt: "vr" }), user?.skinId === 4 && _jsx("img", { src: ARBUZ, alt: "arbuz" })] })), _jsx(AnimatePresence, { children: clickPositions.map(position => (_jsx(motion.div, { className: s.levelClick, initial: { opacity: 1, y: 0, scale: 1 }, animate: { opacity: 0, y: -95, scale: 0.8 }, exit: {
                                        opacity: 0,
                                        y: -95,
                                        scale: 0.8,
                                        transition: { duration: 0.75 }
                                    }, transition: { duration: 0.75 }, style: {
                                        position: 'fixed',
                                        zIndex: 55,
                                        top: position.y,
                                        left: position.x
                                    }, onAnimationComplete: () => {
                                        setClickPositions(prevPositions => prevPositions.filter(pos => pos.id !== position.id));
                                    }, children: user?.computerId }, position.id))) })] }), _jsxs("div", { className: s.levelActions, children: [_jsxs("div", { className: s.levelActionsNext, children: [_jsx(SvgSelector, { id: "pc" }), _jsxs("div", { children: [Math.max(0, userEnergyRemain), " ", _jsxs("span", { children: ["/ ", user?.computer.energy] })] })] }), user?.guildId === null ? (_jsxs("button", { className: s.joinSquad, onClick: () => {
                                    sendClickToServer();
                                    navigate('/squads');
                                }, children: [t('home.join'), _jsx(SvgSelector, { id: "chevron-right" })] })) : (_jsxs(Link, { to: `/squad-detail/${user?.guild?.id}`, onClick: sendClickToServer, className: s.friends, children: [_jsxs("div", { className: s.friendsAvatar, style: { position: 'relative' }, children: [_jsx("img", { src: `https://t.me/i/userpic/320/${user?.guild?.username}.jpg`, onLoad: (e) => {
                                                    const target = e.currentTarget;
                                                    if (target.naturalWidth === 0
                                                        || target.naturalHeight === 0) {
                                                        target.style.display = 'none';
                                                        user?.guild.username?.slice(0, 2)?.toUpperCase();
                                                    }
                                                } }), _jsx(motion.div, { className: s.clan, initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 1, ease: 'easeInOut' }, style: {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }, children: user?.guild?.title?.slice(0, 2)?.toUpperCase() || 'EL' })] }), user?.guild?.title && user?.guild?.title?.length > 10
                                        ? user?.guild?.title?.slice(0, 10) + '...'
                                        : user?.guild?.title] }))] })] })] }));
};
