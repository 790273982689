/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class DailyBonusApi {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async postDailyBonus() {
        return this.apiService.post('dailyBonus', {});
    }
}
