/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-promise-reject-errors */
import WebAppSDK from '@twa-dev/sdk';
export class TelegramStorage {
    constructor() {
        this._data = WebAppSDK.CloudStorage;
    }
    save(key, data) {
        try {
            this._data.setItem(`mnemonic-game-${key}`, data);
            return true;
        }
        catch (error) {
            console.error(error);
            return false;
        }
    }
    get(key) {
        return new Promise((resolve, reject) => {
            try {
                this._data.getItem(`mnemonic-game-${key}`, (error, result) => {
                    if (error || result === null || result === undefined) {
                        reject(error || 'Result is null or undefined');
                    }
                    else {
                        resolve(result);
                    }
                });
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
    del(key) {
        return new Promise((resolve, reject) => {
            try {
                this._data.removeItem(`mnemonic-game-${key}`, (error, result) => {
                    if (error || result === null || result === undefined) {
                        reject(error || 'Result is null or undefined');
                    }
                    else {
                        resolve(result);
                    }
                });
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
}
