export var RouteName;
(function (RouteName) {
    RouteName["HOME_PAGE"] = "/";
    RouteName["FRIENDS_PAGE"] = "/friends";
    RouteName["BOOSTS_PAGE"] = "/boosts";
    RouteName["EARN_PAGE"] = "/profile";
    RouteName["SQUADS_PAGE"] = "/squads";
    RouteName["TOP_PAGE"] = "/top";
    RouteName["SQUAD_DETAIL_PAGE"] = "/squad-detail/:id";
    RouteName["TASK_PAGE"] = "/task/:id";
    RouteName["BUY_PAGE"] = "/buy";
    RouteName["SOMETHING_WENT_WRONG_PAGE"] = "/something_went_wrong";
})(RouteName || (RouteName = {}));
