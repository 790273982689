import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import WebAppSDK from '@twa-dev/sdk';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import * as HeheheSticker from '../../shared/lib/images/stickers/oops.json';
import { prevLocation } from '../../features/prev-location/model';
import { Button } from '../../shared/ui/components';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import { buyJettonsState, setAmountValue, setPayToken } from '../../features/buy-jettons/model';
import { DepositApiService } from '../../entities/deposit/api/depositApi';
import s from './buy.module.scss';
export const Buy = () => {
    const prevLoc = useUnit(prevLocation);
    const { t } = useTranslation();
    const { amountValue, assetsData, payToken } = useUnit(buyJettonsState);
    const [isCreatingInvoices, setIsCreatingInvoices] = useState(false);
    const navigate = useNavigate();
    const formattedAmount = (amount, tokenPriceUSD) => {
        const parsedTokenPriceUSD = parseFloat(tokenPriceUSD ?? '0');
        const parsedAmount = parseFloat(amount);
        if (isNaN(parsedTokenPriceUSD) || isNaN(parsedAmount)) {
            return '0.00';
        }
        const result = (parsedAmount * 1) / parsedTokenPriceUSD;
        const roundedResult = Math.round(result * 100) / 100;
        const formattedResult = roundedResult.toFixed(2);
        return formattedResult;
    };
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: HeheheSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const depositApi = new DepositApiService();
    const createInvoice = async (token, amount) => {
        const invoiceToken = token === 'TON' ? 'TONCOIN' : token;
        try {
            setIsCreatingInvoices(true);
            const res = await depositApi.postDeposit(invoiceToken, amount);
            WebAppSDK.openTelegramLink(res);
            if (res) {
                setIsCreatingInvoices(false);
            }
        }
        catch (err) {
            console.error(err);
            setIsCreatingInvoices(false);
        }
    };
    const handleBack = () => {
        navigate(prevLoc.length > 2 ? prevLoc : '/');
        WebAppSDK.BackButton.hide();
    };
    useEffect(() => {
        WebAppSDK.BackButton.show();
        WebAppSDK.BackButton.onClick(handleBack);
        return () => WebAppSDK.BackButton.offClick(handleBack);
    }, []);
    return (_jsxs("div", { className: s.inner, children: [_jsx("h1", { className: s.innerTitle, children: t('buy.title') }), _jsxs("div", { className: s.innerAmount, children: [_jsx("span", { children: t('buy.enter-amount') }), _jsxs("div", { className: s.innerAmountInput, children: [_jsx("input", { type: "text", value: amountValue, inputMode: "numeric", pattern: "[0-9]*", onChange: (e) => {
                                    let inputValue = e.target.value.replace(/,/g, '.');
                                    inputValue = inputValue.replace(/^0+(?!$|\.)/, '');
                                    inputValue = inputValue.replace(/[^0-9.]+/, '');
                                    inputValue = inputValue.length === 0 ? '0' : inputValue;
                                    const maxLimit = 6000000;
                                    inputValue = Math.min(Number(inputValue), maxLimit).toString();
                                    const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
                                    if (isValidInput) {
                                        setAmountValue(Number(inputValue));
                                        assetsData
                                            && setPayToken({
                                                ...assetsData[0],
                                                amount: formattedAmount(inputValue, assetsData[0].tokenPriceUSD)
                                            });
                                    }
                                } }), _jsx("div", { className: s.innerAmountInputCoin, children: _jsx(SvgSelector, { id: "usd-icon" }) })] }), _jsxs("div", { className: s.innerAmountBottom, children: [_jsxs("p", { children: [_jsxs("div", { children: [t('common.bonus'), ":"] }), " ", _jsx(SvgSelector, { id: "logo-coin-17" }), " 100K", ' ', _jsx("b", { children: t('common.for-every-1$') })] }), _jsxs("p", { children: [_jsxs("div", { children: [t('common.minimum'), ":"] }), "$6"] })] }), _jsxs("div", { className: s.innerAmountActions, children: [_jsx(Button, { onClick: () => {
                                    setAmountValue(10);
                                    assetsData
                                        && setPayToken({
                                            ...assetsData[0],
                                            amount: formattedAmount(10, assetsData[0].tokenPriceUSD)
                                        });
                                }, children: "$10" }), _jsx(Button, { onClick: () => {
                                    setAmountValue(50);
                                    assetsData
                                        && setPayToken({
                                            ...assetsData[0],
                                            amount: formattedAmount(50, assetsData[0].tokenPriceUSD)
                                        });
                                }, children: "$50" }), _jsx(Button, { onClick: () => {
                                    setAmountValue(100);
                                    assetsData
                                        && setPayToken({
                                            ...assetsData[0],
                                            amount: formattedAmount(100, assetsData[0].tokenPriceUSD)
                                        });
                                }, children: "$100" })] })] }), _jsx("h2", { className: s.innerTitleTokens, children: t('buy.select-token') }), _jsx("ul", { className: s.innerTokens, children: assetsData && assetsData.length > 0 ? (assetsData.map(el => (_jsxs("li", { className: `${s.innerToken} ${payToken?.tokenAddress[0] === el.tokenAddress[0]
                        ? s.innerTokenActive
                        : ''}`, onClick: () => {
                        setPayToken({
                            ...el,
                            amount: formattedAmount(amountValue, el.tokenPriceUSD)
                        });
                    }, children: [_jsxs("div", { className: s.innerTokenLeft, children: [_jsx("img", { src: el.tokenLogo, alt: el.token }), el.token] }), _jsx("div", { className: s.tokenAmount, children: formattedAmount(amountValue, el.tokenPriceUSD) })] }, el.token)))) : (_jsx("div", { className: s.innerError, children: _jsx(Lottie, { options: approveOptions, height: 200, isClickToPauseDisabled: true, width: 200 }) })) }), assetsData && assetsData.length > 0 && (_jsx(Button, { onClick: () => amountValue < 6
                    ? WebAppSDK.showAlert(t('oops.issues3'))
                    : createInvoice(payToken?.token ?? '', Number(payToken?.amount) ?? 0), disabled: isCreatingInvoices, isLoading: isCreatingInvoices, children: t('buttons.pay') }))] }));
};
