// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maezozdxy93nGPs_5NBA{font-size:20px;font-weight:700;margin:20px 0 12px 0}.t7rKEm7qXvegqHV2EaST{margin-top:-40px}.xzPJV91pZ4CVphfcCsjV{padding:18px 5px;border-radius:16px;background:rgba(33,52,69,.8)}.xzPJV91pZ4CVphfcCsjV:not(:last-child){margin-bottom:15px}`, "",{"version":3,"sources":["webpack://./src/widgets/skins/ui/skin-list/skin-list.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,oBAAA,CACA,sBACE,gBAAA,CAIJ,sBACE,gBAAA,CACA,kBAAA,CACA,4BAAA,CACA,uCACE,kBAAA","sourcesContent":[".boostsTitle {\n  font-size: 20px;\n  font-weight: 700;\n  margin: 20px 0 12px 0;\n  &Earn {\n    margin-top: -40px;\n  }\n}\n\n.boostsLevels {\n  padding: 18px 5px;\n  border-radius: 16px;\n  background: rgba(33, 52, 69, 0.80);\n  &:not(:last-child) {\n    margin-bottom: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boostsTitle": `maezozdxy93nGPs_5NBA`,
	"boostsTitleEarn": `t7rKEm7qXvegqHV2EaST`,
	"boostsLevels": `xzPJV91pZ4CVphfcCsjV`
};
export default ___CSS_LOADER_EXPORT___;
