import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import WebAppSDK from '@twa-dev/sdk';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { getUserData, userState } from '../../../../features/user/model';
import { getHistoryCases, setShowHistory } from '../../../../features/history/model';
import { caseDetails, handleCase, setShowBoxDetails, showBoxDetails } from '../../../../features/box/model';
import { Case } from '../case';
import { CaseDetail } from '../case-detail';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import SimpleBox from '../../../../shared/lib/images/boxes/simple_box.png';
import RareBox from '../../../../shared/lib/images/boxes/rare_box.png';
import EpicBox from '../../../../shared/lib/images/boxes/epic_box.png';
import LegendaryBox from '../../../../shared/lib/images/boxes/legendary_box.png';
import DemonBox from '../../../../shared/lib/images/boxes/demon_box.png';
import HackerBox from '../../../../shared/lib/images/boxes/hacker_box.png';
import MetaBox from '../../../../shared/lib/images/boxes/meta_box.png';
import Ordinary from '../../../../shared/lib/images/boxes/preview/ordinary.png';
import Rare from '../../../../shared/lib/images/boxes/preview/rare.png';
import Epic from '../../../../shared/lib/images/boxes/preview/epic.png';
import Legendary from '../../../../shared/lib/images/boxes/preview/legendary.png';
import Demon from '../../../../shared/lib/images/boxes/preview/demon.png';
import Hacker from '../../../../shared/lib/images/boxes/preview/hacker.png';
import Meta from '../../../../shared/lib/images/boxes/preview/meta.png';
import { CaseApiService } from '../../../../entities/case';
import s from './case-list.module.scss';
import { casesState } from '../../../../features/case/model';
import { CaseSkeleton } from '../case/case-skeleton';
export const CaseList = () => {
    const { user } = useUnit(userState);
    const caseDetail = useUnit(caseDetails);
    const showCaseDetail = useUnit(showBoxDetails);
    const { t } = useTranslation();
    const TgObj = WebAppSDK;
    const { caseList, isLoading, isError } = useUnit(casesState);
    // Open case logic
    const [caseResult, setCaseResult] = useState(undefined);
    const [caseProcessing, setCaseProcessing] = useState(false);
    const [position, setPosition] = useState(0);
    const caseOpen = new CaseApiService();
    const handleOpenCase = async (boxId) => {
        try {
            setCaseProcessing(true);
            const result = await caseOpen.buyBox(boxId);
            if (result) {
                const { win } = result;
                setCaseResult({ win, box: boxId });
                setCaseProcessing(false);
                getUserData({ shouldSetLoading: false });
                getHistoryCases();
                TgObj.MainButton.hideProgress();
                TgObj.MainButton.hide();
                TgObj.MainButton.color = '#007AFF';
            }
            else {
                TgObj.MainButton.show();
                TgObj.MainButton.hideProgress();
                getUserData({ shouldSetLoading: false });
                console.error('Failed to open case. Result is falsy.');
                // WebAppSDK.showAlert(t('oops.issues2'))
                setPosition(0);
                setCaseProcessing(false);
            }
        }
        catch (error) {
            WebAppSDK.showAlert(t('oops.issues2'));
            TgObj.MainButton.show();
            console.error('Error opening case:', error);
        }
    };
    useEffect(() => {
        if (showCaseDetail) {
            window.document.body.style.overflow = 'hidden';
        }
        return () => {
            window.document.body.style.overflow = 'visible';
        };
    }, [showCaseDetail]);
    function getCaseTitle(caseId) {
        switch (caseId) {
            case 1:
                return 'cases.ordinary';
            case 2:
                return 'cases.rare';
            case 3:
                return 'cases.epic';
            case 4:
                return 'cases.legendary';
            case 5:
                return 'cases.demon';
            case 6:
                return 'cases.hacker';
            case 7:
                return 'cases.meta';
            default:
                return '';
        }
    }
    function getCasePreview(caseId) {
        switch (caseId) {
            case 1:
                return SimpleBox;
            case 2:
                return RareBox;
            case 3:
                return EpicBox;
            case 4:
                return LegendaryBox;
            case 5:
                return DemonBox;
            case 6:
                return HackerBox;
            case 7:
                return MetaBox;
            default:
                return '';
        }
    }
    function getCasePreviewFull(caseId) {
        switch (caseId) {
            case 1:
                return Ordinary;
            case 2:
                return Rare;
            case 3:
                return Epic;
            case 4:
                return Legendary;
            case 5:
                return Demon;
            case 6:
                return Hacker;
            case 7:
                return Meta;
            default:
                return '';
        }
    }
    function getCaseAwards(caseId) {
        const items = caseList?.items.filter(el => el.caseId === caseId);
        const categories = caseList?.categories.filter(el => Number(el.caseId) === caseId);
        const awards = [];
        const hasLowcheck = categories?.some(cat => cat.type === 'LOWCHECK');
        const hasHighcheck = categories?.some(cat => cat.type === 'HIGHCHECK');
        items?.forEach((el) => {
            awards.push({ type: 'coin', win: el.reward });
            if (hasLowcheck) {
                awards.push({ type: 'DFC', win: 1 });
            }
            if (hasHighcheck) {
                awards.push({ type: 'ARBUZ', win: 2 });
            }
        });
        return awards;
    }
    return (_jsxs(_Fragment, { children: [showCaseDetail && (_jsx(CaseDetail, { caseId: caseDetail?.caseId ?? 0, setCaseId: handleCase, resultData: caseResult, setResultData: setCaseResult, handleOpenCase: handleOpenCase, caseProcessing: caseProcessing, position: position, setPosition: setPosition })), _jsx(_Fragment, { children: _jsxs(_Fragment, { children: [_jsxs("div", { className: s.boostsTop, children: [_jsx("div", { className: s.boostsTitle, children: t('boosts.cases-title') }), _jsxs("div", { className: s.boostsHistory, onClick: () => setShowHistory(true), children: [_jsx(SvgSelector, { id: "history" }), t('common.history')] })] }), _jsx("div", { className: s.boostsLevels, children: isLoading ? new Array(7).fill(null).map(_ => (_jsx(CaseSkeleton, {}, v1()))) : (caseList && caseList.cases && caseList.cases?.sort((a, b) => {
                                if (a.type.toLowerCase() === 'coin')
                                    return -1;
                                if (b.type.toLowerCase() === 'coin')
                                    return 1;
                                return a.price - b.price;
                            }).map(el => (_jsx(Case, { preview_img: getCasePreview(el.id), price: el.price, allow_to_buy: el.type.toLowerCase() === 'usd' ? (user?.usdBalance ?? 0) >= el.price : (user?.balance ?? 0) >= el.price, title: getCaseTitle(el.id), onClick: () => {
                                    setShowBoxDetails(true);
                                    handleCase({
                                        caseId: el.id,
                                        type: el.type.toLowerCase(),
                                        price: el.price,
                                        title: getCaseTitle(el.id),
                                        previewImg: getCasePreviewFull(el.id),
                                        awards: getCaseAwards(el.id)
                                    });
                                }, typeCase: el.type.toLowerCase(), disable: caseProcessing }, v1())))) })] }) })] }));
};
