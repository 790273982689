import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { useUnit } from 'effector-react';
import { Alert, Button } from '../../../shared/ui/components';
import { SvgSelector } from '../../../shared/lib/assets/svg-selector';
import { updatePrevLocation } from '../../../features/prev-location/model';
import { userState } from '../../../features/user/model';
import Logo from '../../../shared/lib/images/friends/logo.png';
import s from './friends-top.module.scss';
export const FriendsTop = () => {
    const navigate = useNavigate();
    const { user } = useUnit(userState);
    const { t } = useTranslation();
    const [isCopiedLink, setIsCopiedLink] = useState(false);
    const handleNavigate = () => {
        navigate('/top');
        updatePrevLocation('/friends');
    };
    return (_jsxs("div", { className: s.friends, children: [isCopiedLink && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedLink(false), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", className: s.alert, children: t('friends.alert') })), _jsx("h1", { className: s.friendsTitle, children: t('friends.title') }), _jsx("div", { className: s.friendsDetail, children: _jsxs("button", { className: s.friendsDetailRating, onClick: handleNavigate, children: [_jsx(SvgSelector, { id: "trophy" }), t('home.stats'), _jsx(SvgSelector, { id: "chevron-right" })] }) }), _jsxs("div", { className: s.friendsDetailBonus, children: [_jsxs("div", { className: s.friendsDetailBonusInner, children: [_jsx("img", { src: Logo, width: "61", height: "61", alt: "logo" }), _jsxs("div", { children: [_jsxs("h3", { children: [" ", t('friends.invite-friend2')] }), _jsxs("p", { children: [_jsxs("span", { children: [_jsx(SvgSelector, { id: "logo-coin-17" }), " +100\u041A"] }), " ", t('friends.for-you')] })] })] }), _jsxs(Button, { className: s.friendsInvite, onClick: () => {
                            setIsCopiedLink(true);
                            copy(`https://t.me/mnemonic_game_bot/start?startapp=ref_${user?.telegramId}`);
                        }, children: [_jsx("div", { children: t('friends.invite-friend') }), _jsx(SvgSelector, { id: "chevron-right" })] })] })] }));
};
