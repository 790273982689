// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IxoWcnYlh2p2iS2cUuoK{display:flex;align-items:center;justify-content:center;flex-direction:column;height:100vh}.VZgsrE7_5ymOpWFAV9X3{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.Jipwj7PlriXf9QqTExhD{font-size:35px;font-weight:760;line-height:120%;max-width:250px;margin:10px 0;text-align:center;color:var(--tg-theme-text-color)}.jraDdwMsfmzmCTdzx7zL{color:#3495ff}.JFG24rREL9AP599cyx0l{font-size:18px;margin-bottom:12px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/pages/something-went-wrong/something-went-wrong.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,YAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,gCAAA,CACA,sBACE,aAAA,CAGJ,sBACE,cAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".inner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Title {\n    font-size: 35px;\n    font-weight: 760;\n    line-height: 120%;\n    max-width: 250px;\n    margin: 10px 0;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n    &Red {\n      color: #3495FF;\n    }\n  }\n  &Text {\n    font-size: 18px;\n    margin-bottom: 12px;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `IxoWcnYlh2p2iS2cUuoK`,
	"innerImg": `VZgsrE7_5ymOpWFAV9X3`,
	"innerTitle": `Jipwj7PlriXf9QqTExhD`,
	"innerTitleRed": `jraDdwMsfmzmCTdzx7zL`,
	"innerText": `JFG24rREL9AP599cyx0l`
};
export default ___CSS_LOADER_EXPORT___;
