import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { v1 } from 'uuid';
import { Button, SkeletonInfo } from '../../shared/ui/components';
import { SvgSelector } from '../../shared/lib/assets/svg-selector';
import { formatNumber } from '../../shared/utils/formatNumber';
import { TopApiService } from '../../entities/top';
import { SquadsApiService } from '../../entities/squads';
import { updatePrevLocation } from '../../features/prev-location/model';
import s from './squads.module.scss';
export const Squads = ({}) => {
    const [hideGroups, setHideGroups] = useState(false);
    const [joinGroupValue, setJoinGroupValue] = useState('');
    const [squads, setSquads] = useState(undefined);
    const [squadsLoading, setSquadsLoading] = useState(false);
    const [createdSquad, setCreatedSquad] = useState(false);
    const topApiService = new TopApiService();
    const squadsApiService = new SquadsApiService();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const fetchTop = async () => {
        try {
            setSquadsLoading(true);
            const res = await topApiService.getTop();
            setSquads(res.squads);
        }
        catch (error) {
            console.error('Error fetching top:', error);
        }
        finally {
            setSquadsLoading(false);
        }
    };
    useEffect(() => {
        fetchTop();
    }, []);
    const handleBack = () => {
        if (hideGroups) {
            setHideGroups(false);
        }
        else {
            WebAppSDK.BackButton.hide();
            navigate('/');
        }
    };
    const handleJoinGroup = () => {
        let usernameToSend = '';
        if (joinGroupValue.includes('@')) {
            usernameToSend = joinGroupValue.split('@')[1];
        }
        else {
            usernameToSend = joinGroupValue.split('/')[joinGroupValue.split('/').length - 1];
        }
        try {
            setCreatedSquad(true);
            squadsApiService.joinSquad(usernameToSend.toLowerCase()).then((res) => {
                navigate(`/squad-detail/${res.guildId}`);
                updatePrevLocation('/squads');
            });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setCreatedSquad(false);
        }
    };
    useEffect(() => {
        WebAppSDK.BackButton.show();
        WebAppSDK.BackButton.onClick(handleBack);
        window.scrollTo(0, 0);
        return () => WebAppSDK.BackButton.offClick(handleBack);
    }, [hideGroups]);
    return (_jsx(_Fragment, { children: !hideGroups ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.top, children: [_jsx("h3", { className: s.topTitle, children: t('squads.title-join') }), _jsx("p", { className: s.topDescription, children: t('squads.description') }), _jsx(Button, { className: s.topButton, color: "green", onClick: () => setHideGroups(true), children: t('squads.select-another-group') })] }), _jsx("div", { className: s.squadsList, children: squadsLoading ? (new Array(10).fill(null).map(_ => (_jsxs("div", { className: s.squadsItem, children: [_jsxs("div", { className: s.squadsItemLeft, children: [_jsx("div", { className: s.squadsItemAvatar }), _jsxs("div", { className: s.squadsItemInfo, children: [_jsx("div", { className: s.squadsItemName, children: _jsx(SkeletonInfo, { height: "15", backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" }) }), _jsxs("div", { className: s.friendsItemBalance, children: [_jsx(SvgSelector, { id: "coin-small" }), ' ', _jsx(SkeletonInfo, { height: "15", backgroundColor: "rgba(54, 100, 61, 0.8)", foregroundColor: "rgba(25, 48, 29, 1)" })] })] })] }), _jsx("div", { className: s.squadsItemStats, children: _jsx(SvgSelector, { id: "chevron-right" }) })] }, v1())))) : squads && squads.length ? (squads.map(el => (_jsxs("div", { className: s.squadsItem, onClick: () => {
                            navigate(`/squad-detail/${el.guild_id}`);
                            updatePrevLocation('/squads');
                        }, children: [_jsxs("div", { className: s.squadsItemLeft, children: [_jsxs("div", { className: s.squadsItemAvatar, style: { position: 'relative' }, children: [_jsx("img", { src: el?.guild_avatar_url, onLoad: (e) => {
                                                    const target = e.currentTarget;
                                                    if (target.naturalWidth === 0
                                                        || target.naturalHeight === 0) {
                                                        target.style.display = 'none';
                                                        el?.guild_title?.slice(0, 3)?.toUpperCase();
                                                    }
                                                }, style: { zIndex: 10 } }), _jsx("div", { style: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, children: el?.guild_title?.slice(0, 3)?.toUpperCase() })] }), _jsxs("div", { className: s.squadsItemInfo, children: [_jsx("div", { className: s.squadsItemName, children: el.guild_title }), _jsxs("div", { className: s.friendsItemBalance, children: [_jsx(SvgSelector, { id: "coin-small" }), ' ', formatNumber(el.total_balance)] })] })] }), _jsx("div", { className: s.squadsItemStats, children: _jsx(SvgSelector, { id: "chevron-right" }) })] }, v1())))) : (_jsx("p", { className: s.oops, children: t('squads.empty-groups') })) })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.top, children: [_jsx("h3", { className: s.topTitle, children: t('squads.title-another-group') }), _jsx("p", { className: s.topDescription, children: t('squads.description-another-group') })] }), _jsx("input", { className: s.joinInput, type: "text", value: joinGroupValue, placeholder: `${t('squads.example')}: durov`, onChange: (e) => setJoinGroupValue(e.target.value) }), _jsx(Button, { className: s.joinButton, color: "green", onClick: handleJoinGroup, disabled: createdSquad, children: t('buttons.join-group') })] })) }));
};
