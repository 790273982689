import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { userState } from '../../../../features/user/model';
import { SkinsData } from '../../../../shared/mock/skins';
import { Skin } from '../skin';
import s from './skin-list.module.scss';
export const SkinList = () => {
    const { user } = useUnit(userState);
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.boostsTitle, children: t('common.skins') }), _jsx("div", { className: s.boostsLevels, children: SkinsData?.slice((user?.skinId ?? 1) - 1).map(el => (_jsx(Skin, { skinData: el, user: user }, v1()))) })] }));
};
