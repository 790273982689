/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-extraneous-dependencies */
import { TonApi } from '@delab-team/ton-api-sdk';
import { createEvent, createStore, combine } from 'effector';
import { tokensData } from '../../shared/mock/tokens';
export const setAssetsData = createEvent();
export const assetsDataStore = createStore(null)
    .on(setAssetsData, (_, assetsData) => assetsData);
export const setPayToken = createEvent();
export const payTokenStore = createStore(null)
    .on(setPayToken, (_, payToken) => payToken);
export const isLoadingStore = createStore(false);
export const setIsLoading = createEvent();
export const setAmountValue = createEvent();
export const amountValueStore = createStore(25)
    .on(setAmountValue, (_, amountValue) => amountValue);
isLoadingStore.on(setIsLoading, (_, isLoading) => isLoading);
const api = new TonApi('AFJOFCU7U3SD7DYAAAAFNHSJF3J4OKQQEXIMHCEQSXUQJAGVP227ZLZ3G3MQTBEU5P3JMRI', 'mainnet');
export const getJettonsData = createEvent();
const formattedAmount = (amount, tokenPriceUSD) => {
    const parsedTokenPriceUSD = parseFloat(tokenPriceUSD ?? '0');
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedTokenPriceUSD) || isNaN(parsedAmount)) {
        return '0.00';
    }
    const result = (parsedAmount * 1) / parsedTokenPriceUSD;
    const roundedResult = Math.round(result * 100) / 100;
    const formattedResult = roundedResult.toFixed(2);
    return formattedResult;
};
getJettonsData.watch(async () => {
    try {
        const tokenAddressesArr = tokensData?.map(item => item?.tokenAddress[0]);
        const priceRes = await api?.Rates.getTokenPrice(tokenAddressesArr, 'usd');
        // @ts-ignore
        const tonPrice = priceRes?.rates?.['0:9a8da514d575d20234c3fb1395ee9138f5f1ad838abc905dc42c2389b46bd015']?.prices?.USD;
        const updatedTokens = tokensData?.map((el) => {
            if (el.token === 'TON') {
                return {
                    ...el,
                    tokenPriceUSD: parseFloat(tonPrice).toFixed(2)
                };
            }
            const matchingAddresses = tokenAddressesArr?.filter(el3 => priceRes?.rates?.hasOwnProperty(el3));
            for (const matchingAddress of matchingAddresses ?? '') {
                if (el.tokenAddress[0] === matchingAddress) {
                    // @ts-ignore
                    const matchingBalance = priceRes?.rates[matchingAddress]?.prices?.USD;
                    return {
                        ...el,
                        tokenPriceUSD: parseFloat(matchingBalance).toFixed(2)
                    };
                }
            }
            return el;
        });
        const finalUpdatedTokens = [...updatedTokens];
        setAssetsData(finalUpdatedTokens);
        setPayToken({
            ...finalUpdatedTokens[0],
            amount: formattedAmount(25, finalUpdatedTokens[0].tokenPriceUSD)
        });
    }
    catch (error) {
        console.error(error);
    }
});
export const buyJettonsState = combine({
    assetsData: assetsDataStore,
    isLoading: isLoadingStore,
    amountValue: amountValueStore,
    payToken: payTokenStore
});
