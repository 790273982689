import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { useUnit } from 'effector-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { prevLocation, updatePrevLocation } from '../../../../features/prev-location/model';
import { userState } from '../../../../features/user/model';
import { SvgSelector } from '../../../lib/assets/svg-selector';
import s from './balance.module.scss';
export const Balance = ({ prevLoc }) => {
    const prevLocationState = useUnit(prevLocation);
    const { user } = useUnit(userState);
    const [startAmount, setStartAmount] = useState(0);
    const [endAmount, setEndAmount] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (user && typeof user.balance === 'number') {
            setStartAmount(endAmount);
            setEndAmount(user.balance);
        }
    }, [user, location.pathname]);
    const handleBuy = () => {
        updatePrevLocation(prevLoc);
        navigate('/buy');
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: `${s.statsInfo} ${location.pathname === '/profile' || location.pathname === '/boosts' ? s.statsInfoProfile : ''}`, children: [_jsxs("div", { className: `${s.statsInfoLogo} ${location.pathname === '/profile' || location.pathname === '/boosts' ? s.statsInfoLogoBoosts : ''}`, children: [(location.pathname === '/profile' || location.pathname === '/boosts') && (_jsx(SvgSelector, { id: "logo-coin-small" })), location.pathname !== '/profile' && location.pathname !== '/boosts' && (_jsx(SvgSelector, { id: "coin" }))] }), _jsx("div", { className: `${s.statsBalance} ${location.pathname === '/profile' || location.pathname === '/boosts' ? s.statsBalanceProfile : ''}`, children: _jsx(CountUp, { start: startAmount, end: endAmount, duration: 1, decimals: endAmount % 1 === 0 ? 0 : 2, decimal: "." }) })] }), (location.pathname === '/profile' || location.pathname === '/boosts') && (_jsxs("div", { className: `${s.statsUsdInner} ${location.pathname === '/profile' || location.pathname === '/boosts' ? s.statsUsdInnerProfile : ''}`, children: [_jsxs("div", { className: `${s.statsUsd} `, onClick: handleBuy, children: [(location.pathname === '/profile' || location.pathname === '/boosts') ? (_jsx(SvgSelector, { id: "usd-logo-small" })) : (_jsx(SvgSelector, { id: "usd-logo" })), Number(user?.usdBalance) % 1 === 0 ? user?.usdBalance.toLocaleString('en') : user?.usdBalance.toLocaleString('en', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })] }), _jsx("button", { onClick: handleBuy, children: _jsx(SvgSelector, { id: "add" }) })] }))] }));
};
