import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class SkinApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async buyComputer(computerId) {
        return this.apiService.post(`computer/buy?computerId=${computerId}`, {});
    }
    async buySkin(skinId) {
        return this.apiService.post(`skin/buy?skinId=${skinId}`, {});
    }
}
