import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import { v1 } from 'uuid';
import { mockTasks } from '../../../../shared/mock/tasks';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import { formatNumber } from '../../../../shared/utils/formatNumber';
import { TelegramStorage } from '../../../../shared/utils/telegram-storage';
import Tasks from '../../../../shared/lib/images/tasks/tasks.png';
import { setTasks, taskState } from '../../../../features/taks/model';
import s from './tasks-list.module.scss';
import { SkeletonInfo } from '../../../../shared/ui/components';
export const TasksList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const telegramStorage = new TelegramStorage();
    const { tasks, tasksLoading } = useUnit(taskState);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const completedTasksFromStorage = JSON.parse(await telegramStorage.get('completedTasks') || '{}');
                const updatedMockTasks = mockTasks.map(task => ({
                    ...task,
                    tasks: task.tasks.map(step => ({
                        ...step,
                        completed: completedTasksFromStorage[`${task.id}_${step.id}`] || false
                    }))
                }));
                setTasks(updatedMockTasks);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    const handleTaskClick = (taskId) => {
        navigate(`/task/${taskId}`);
    };
    const areAllTasksCompleted = (task) => task.tasks.every((subtask) => subtask.completed);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: `${s.boostsTitle} ${s.boostsTitleEarn}`, children: t('earn.title') }), _jsxs("div", { className: s.earnLevels, children: [tasksLoading ? new Array(5).fill(null).map(_ => (_jsxs("div", { className: `${s.earnCard}`, children: [_jsx("div", { className: s.skeletonSquare }), _jsxs("div", { className: s.earnCardBody, children: [_jsxs("div", { className: s.earnCardTop, children: [_jsx(SkeletonInfo, { width: 100, height: "18px" }), _jsx("div", { className: s.earnCardAwards, children: _jsx(SvgSelector, { id: "chevron-right" }) })] }), _jsx(SkeletonInfo, { width: 50, height: "16px" })] })] }, v1()))) : tasks?.map(task => (_jsxs("div", { className: `${s.earnCard} ${areAllTasksCompleted(task) ? s.completedTask : ''}`, onClick: () => handleTaskClick(task.id), children: [_jsx("img", { src: task.preview, alt: "join squad" }), _jsxs("div", { className: s.earnCardBody, children: [_jsxs("div", { className: s.earnCardTop, children: [t(task.title), ' ', _jsx("div", { className: s.earnCardAwards, children: areAllTasksCompleted(task) ? (_jsx(SvgSelector, { id: "checked" })) : (_jsx(_Fragment, { children: _jsx(SvgSelector, { id: "chevron-right" }) })) })] }), areAllTasksCompleted(task) ? (_jsx(_Fragment, {})) : (_jsxs("div", { className: s.earnCardAwardsAmount, children: [_jsx(SvgSelector, { id: "coin-small" }), "+", formatNumber(task.reward)] }))] })] }, v1()))), _jsxs("div", { className: s.earnCard, onClick: () => { window.open('https://t.me/delabsupportbot', '_blank'); }, children: [_jsx("img", { src: Tasks, alt: "tasks" }), _jsxs("div", { className: s.earnCardBody, children: [_jsxs("div", { className: s.earnCardTop, children: [t('common.invite-task'), " ", ' ', _jsx("div", { className: s.earnCardAwards, children: _jsx(SvgSelector, { id: "chevron-right" }) })] }), _jsx("div", { className: s.earnCardAwardsAmount, children: _jsx("span", {}) })] })] })] })] }));
};
