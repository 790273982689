import { ApiService } from '../../../shared/utils/api-servies';
const apiUrl = 'https://squid-app-tpgns.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class DepositApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    async postDeposit(coin, amount) {
        return this.apiService.post(`deposit?coin=${coin}&amount=${amount}`, {});
    }
}
