import { createStore, createEvent, combine } from 'effector';
export const setClicks = createEvent();
export const setAwardsClicks = createEvent();
export const setUserEnergyRemain = createEvent();
export const clicks = createStore(0).on(setClicks, (_, payload) => payload);
export const awardsClicks = createStore(parseInt(localStorage.getItem('awardsClicks') || '0', 10)).on(setAwardsClicks, (_, payload) => payload);
export const userEnergyRemain = createStore(0).on(setUserEnergyRemain, (_, payload) => payload);
export const clicksState = combine({
    clicks,
    awardsClicks,
    userEnergyRemain
});
