import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import { formatNumber } from '../../../../shared/utils/formatNumber';
import s from './friend-item.module.scss';
export const FriendItem = ({ username, avatarUrl, balance }) => (_jsx("div", { className: s.friendsItem, children: _jsxs("div", { className: s.friendsItemLeft, children: [_jsxs("div", { className: s.friendsItemAvatar, style: { position: 'relative' }, children: [_jsx("img", { src: avatarUrl, onLoad: (e) => {
                            const target = e.currentTarget;
                            if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                                target.style.display = 'none';
                                username?.slice(0, 2)?.toUpperCase();
                            }
                        }, style: { zIndex: '5' } }), _jsx("div", { style: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }, children: username?.slice(0, 2)?.toUpperCase() || 'EL' })] }), _jsxs("div", { className: s.friendsItemInfo, children: [_jsx("div", { className: s.friendsItemName, children: username }), _jsxs("div", { className: s.friendsItemBalance, children: [_jsx(SvgSelector, { id: "coin-small" }), " ", formatNumber(balance)] })] })] }) }));
