import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SvgSelector } from '../../../../shared/lib/assets/svg-selector';
import s from './case.module.scss';
import { formatNumber } from '../../../../shared/utils/formatNumber';
export const Case = ({ preview_img, price, allow_to_buy, title, onClick, typeCase, disable }) => {
    const formattedAmount = price.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    const displayAmount = formattedAmount.endsWith('.00')
        ? formattedAmount?.slice(0, -3)
        : formattedAmount;
    const navigate = useNavigate();
    // const handleClick = () => allow_to_buy ? onClick() : navigate('/buy')
    const handleClick = () => onClick();
    const { t } = useTranslation();
    return (_jsxs("div", { className: `${s.boostsLevel} ${disable ? s.boostsLevelBuyed : ''}`, onClick: handleClick, children: [_jsx("img", { src: preview_img, alt: "preview_img" }), _jsxs("div", { className: s.boostsLevelBody, children: [_jsxs("div", { className: s.boostsLevelTop, children: [t(title), _jsxs("div", { className: s.boostsLevelAction, children: [_jsxs("div", { className: s.boostsLevelPrice, style: { gap: typeCase === 'usd' ? '1px' : '4px' }, children: [typeCase === 'usd' ? _jsx("span", { children: "$" }) : _jsx(SvgSelector, { id: "logo-coin-17" }), " ", typeCase === 'coin' ? formatNumber(price) : displayAmount] }), _jsx(SvgSelector, { id: "chevron-right" })] })] }), allow_to_buy ? (_jsx("div", { className: s.boostsLevelDescription, children: t('common.can-buy') })) : (_jsx("div", { className: s.boostsLevelDescription, children: t('common.low-balance') }))] })] }));
};
