// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TvUYBZIxAqZugBGrPaFm{font-size:20px;font-weight:700;margin-bottom:12px}.TvUYBZIxAqZugBGrPaFm span{color:rgba(255,255,255,.6)}.wuX8DKWRSoSGNoibf9wY{padding:16px;border-radius:16px;background:rgba(33,52,69,.8)}.R9DYbphcd9dZD9kVCNYm{text-align:center}`, "",{"version":3,"sources":["webpack://./src/widgets/friends-list/ui/friends-list/friends-list.module.scss"],"names":[],"mappings":"AACE,sBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,2BACE,0BAAA,CAGJ,sBACE,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,sBACE,iBAAA","sourcesContent":[".friends {\n  &Title {\n    font-size: 20px;\n    font-weight: 700;\n    margin-bottom: 12px;\n    span {\n      color: rgba(255, 255, 255, 0.6);\n    }\n  }\n  &List {\n    padding: 16px;\n    border-radius: 16px;\n    background: rgba(33, 52, 69, 0.80);  \n    &Empty {\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"friendsTitle": `TvUYBZIxAqZugBGrPaFm`,
	"friendsList": `wuX8DKWRSoSGNoibf9wY`,
	"friendsListEmpty": `R9DYbphcd9dZD9kVCNYm`
};
export default ___CSS_LOADER_EXPORT___;
