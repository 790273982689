import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import s from './alert.module.scss';
export const Alert = ({ type, onClose, buttonClassName, icon, children, autoCloseTimeout, position, centered, className }) => {
    const [isVisible, setIsVisible] = useState(true);
    const getAlertClass = () => {
        switch (type) {
            case 'icon':
                return s.iconAlert;
            case 'important':
                return s.importantAlert;
            case 'rounded':
                return s.roundedAlert;
            case 'border':
                return s.borderAlert;
            case 'extensive-content':
                return s.extensiveContentAlert;
            default:
                return s.defaultAlert;
        }
    };
    const getAlertPositionClass = () => {
        if (centered) {
            return s.centeredAlert;
        }
        switch (position) {
            case 'top-left':
                return s.topLeftAlert;
            case 'top-right':
                return s.topRightAlert;
            case 'bottom-left':
                return s.bottomLeftAlert;
            case 'bottom-right':
                return s.bottomRightAlert;
            default:
                return '';
        }
    };
    useEffect(() => {
        if (autoCloseTimeout && isVisible) {
            const timerId = setTimeout(() => {
                setIsVisible(false);
                if (onClose) {
                    onClose();
                }
            }, autoCloseTimeout);
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [autoCloseTimeout, isVisible, onClose]);
    const handleAlertClose = () => {
        setIsVisible(false);
        if (onClose) {
            onClose();
        }
    };
    return isVisible ? (_jsxs("div", { className: `${s.alert} ${getAlertClass()} ${getAlertPositionClass()} ${className || ''}`, children: [icon && _jsx("div", { className: s.alertIcon, children: icon }), _jsx("div", { className: s.alertContent, children: children })] })) : null;
};
